<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <input
        :required="required"
        :value="value"
        type="number"
        class="form-control w-100"
        @change="changeVal"
        :placeholder="placeholder"
        :step="config.step ? config.step : 1"
      />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      {{ value }}
      <span v-if="config.display_after">{{ config.display_after }}</span>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldNumber",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
