<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <select
        class="form-control"
        :required="required"
        @change="changeVal"
      >
        <option value=""></option>
        <option :selected="optionKey == value" v-for="(option, optionKey) in config.options" :id="option" :value="optionKey">
          {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${option}`) }}
        </option>
      </select>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <template v-if="!config || !config.display">
        {{
          $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options`) ?
            $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${value}`) : $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.${value}`)
        }}
      </template>
      <template v-else-if="config.display == 'stepper'">
        <ul class="step-rendered">
          <li
            v-for="option in config.options"
            :class="{
              'active': value == option
            }"
          >
            <span>
              {{
                $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options`) ?
                  $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${option}`) : $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.${option}`)
              }}
            </span>
          </li>
        </ul>
      </template>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldSelect",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_palette.scss';

.step-rendered {
    width: 100%;
    padding: 0 0 0 40px;
    margin: 0;
    list-style-type: none;
    position: relative;
    overflow: hidden;
    &:before {
        display: block;
        content: '';
        width: 2px;
        background: $color-slate-50;
        height: 100%;
        position: absolute;
        left: 14px;
        top: 0;
    }
    li {
        padding: 6px 0;
        margin: 0;
        position: relative;
        span {
          opacity: 0.5;
        }
        &:before {
            display: block;
            content: "";
            width: 14px;
            height: 14px;
            background: #fff;
            position: absolute;
            left: -32px;
            top: 50%;
            margin: -8px 0 0 0;
            opacity: 1;
            border-radius: 10px;
            border: 2px solid $color-slate-50;
            z-index: 3;
        }
        &.active {
            span {
              opacity: 1;
            }
            font-weight: bold;
            &:before {
                border-color: $color-slate-500;
                background: $color-slate-500;
            }
        }
    }
}
</style>
