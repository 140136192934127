<!-- Compoenent actions  -->
<template>
  <div class="">
    <div class="mb-2 text-grey badge-list d-flex justify-content-end" v-if="show_sort">
      <div class="badge badge-title mr-2">{{ $t('common.sort_by') }}</div>
      <div
        :key="sortkey"
        :class="{
          'badge cursor-pointer': true,
          'text-primary': sortkey !== $store.state.actionsboard.filters.activeSort,
          'badge-dark text-white': sortkey === $store.state.actionsboard.filters.activeSort,
          ['badge-'+sort]: true
        }"
        v-for="(sort, sortkey) in sorts"
        @click="setSort(sortkey, sortkey === $store.state.actionsboard.filters.activeSort)"
      >
        {{ sort }}
        <span v-if="sortkey === $store.state.actionsboard.filters.activeSort">
          <i :class="'icon-sort icon-sort-amount-' + $store.state.actionsboard.filters.activeSortDirection.toLowerCase()"></i>
        </span>
      </div>
    </div>
    <div class="clear"></div>
    <div
      v-if="actions.length > 0"
    >
      <div :is="actions.length < 12 ? 'transition-group' : 'div'" name="trs-list">
        <div
          v-for="(item, index) in sortedActions"
          :key="item.id"
          class="trs-list-item"
        >
<!--           <div
            class="mb-2 task element-drag"
            :task-index="index"
            :data-element="item.id"
          > -->
            <TodoListing
              :item="item"
              :users="users"
              :selectTaskDetails="selectTaskDetails"
              :priorities="priorities"
              :pdca_values="pdca_values"
              :formatSelectedDate="formatSelectedDate"
              :editCallback="editCallback"
            />
          <!-- </div> -->
        </div>
      </div>

    </div>
    <div class="text-uppercase font-size-sm font-weight-semibold text-center" v-else-if="module == 'modules.actionsboard'">
      {{ $t(module + '.aucun_result_actions') }}
    </div>
  </div>
</template>

<script>

import TodoListing from './_TodoListing'

/**
 * @group _ Module Actionsboard
 * This component display all actions list
 */
  export default {
    name: 'TodoActionsList',
    props: {
      formatSelectedDate: {
        type: [Object, Function],
      },
      actions: {
        type: [Object, Array],
      },
      priorities: {
        type: Object,
      },
      pdca_values: {
        type: Array,
      },
      users: {
        type: [Object, Array],
      },
      show_sort: {
        type: Boolean,
        default: true,
      },
      editCallback: {
        type: Function,
        default: null,
      },
    },
    components: {
      TodoListing
    },
    data () {
      return {
        module: 'modules.'+this.$route.meta.moduleName,
        sorts: {
          priority: this.$t('todos.priority'),
          title: this.$t('common.title'),
          date: this.$t('todos.due_date_placeholder'),
          responsible: this.$t('todos.responsible')
        },
        filters: [],
        worldId: this.$store.getters['auth/getWorld'].id,
        selectedTaskIs : {}
      }
    },
    methods: {
      /**
       * @vuese
       * This function to sort data DESC or ASC and choix type date title priopite and responsable
       * @arg
       */
      setSort (sort, active) {
        this.$store.commit('actionsboard/setFilters',{key: 'activeSort', value: sort})
        this.$store.commit('actionsboard/setFilters',{key: 'sortTaskIsActivited', value: active})
        if (active) {
          this.$store.state.actionsboard.filters.activeSortDirection = this.$store.state.actionsboard.filters.activeSortDirection === 'DESC' ? 'ASC' : 'DESC'
          this.$store.commit('actionsboard/setFilters',{key: 'activeSortDirection', value: this.$store.state.actionsboard.filters.activeSortDirection })
        }
      },
      /**
       * @vuese
       * this function to show detail action selected
       * @arg
       */
      selectTaskDetails (task) {
        if (!(typeof this.selectedTaskIs.id !== 'undefined' && this.selectedTaskIs.id == task.id)) {
          this.selectedTaskIs = task
          this.$emit('onShowDetailTodo', {}, false)
          setTimeout(() => {
            this.$emit('onShowDetailTodo', task, true)
          }, 10)
        }
      },
      //set New DAte Update
      /**
       * @vuese
       * this function change dateStart for action
       * @arg
       */
      chanegDate(elem) {
        this.data.filter(item => {
          if (item.id === elem.id) {
            item.startDate = elem.startDate
          }
        })
      },
    },
    computed: {
      /**
       * @vuese
       * this function get state action data and filter by all type filter exist in actionsboard
       * @arg
       */
      sortedActions () {
        let sort = {}
        if (this.$store.state.actionsboard.filters.activeSort === 'date') {
          let nullVal = 9999999999999
          if (this.$store.state.actionsboard.filters.activeSortDirection === 'DESC') {
            nullVal = -9999999999999
          }

          sort = _.sortBy(this.actions, [
            item => item.startDate ? new Date(item.startDate).getTime() : nullVal, // order null last
            'id',
          ]);
        } else if (this.$store.state.actionsboard.filters.activeSort === 'title') {
          sort = _.sortBy(this.actions, [
            item => item.name ? item.name.toLowerCase() : '',
            'id',
          ])
        } else if (this.$store.state.actionsboard.filters.activeSort === 'priority') {
          sort = _.sortBy(this.actions, [
            item => this.priorities[item.priority] ? this.priorities[item.priority].number : null,
            'id',
          ])
        } else if (this.$store.state.actionsboard.filters.activeSort === 'responsible') {
          let sortUsers = _.sortBy(this.users, [item => item.full_name])
          let sortUser = this.actions
          sortUser.map(item => {
            if (item.owner != null) {
              this.users.map(user => {
                if (user.id == item.owner) {
                  return item.full_name = user.full_name
                }
              })
            } else {
              return item.full_name = this.users[item.owner].full_name
            }
          })
          sort = _.sortBy(sortUser, [
            item => item.full_name,
            'id',
          ])
        } else {
          sort = this.actions
        }
        if (this.$store.state.actionsboard.filters.activeSortDirection === 'DESC') {
          sort = sort.reverse()
        }
        return sort
      }
    }
  }
</script>

<style lang="scss" scoped>
.loading-action {
  text-align: center;
  i {
      font-size: 28px;
  }
}
.badge-title {
  padding: 0.3125rem 0rem 0.3125rem 0rem;
}
.badge-Responsable, .badge-Date {
  float: right;
}
.badge-title, .badge-Titre, .badge-Priorité {
  float: left;
}
.clear {
  clear: both;
}
.badge{
  margin-bottom: .625rem!important;
}
i.icon-sort {
  font-size: 13px;
  margin-bottom: -3px;
}

.trs-list-item {
  transition: all .3s;
  display: inline-block;
  width: 100%;
  margin-right: 10px;
}
.trs-list-enter, .trs-list-leave-to {
  opacity: 0;
  // transform: scale(.9);
}
.trs-list-leave-active {
  width: 100%;
  position: absolute;
}
</style>
