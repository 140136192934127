<template>

  <div>
    <div
      :class="{
        'mt-4': index > 0,
        'mb-2 font-weight-bold text-dark h1': true,
      }"
    >
      {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}`) }}
    </div>
  </div>

</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldFormpart",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
