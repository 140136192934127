<template>

  <div>

    <div class="input-group mb-3 w-100">
      <div class="input-group-prepend">
        <span :class="`input-group-text bg-${classColorName} border-0`" id="inputGroup-sizing-default" v-tooltip="$t('modules.process.process.template.addons.rating.placeholder')"><i class="icon-star-full2"></i></span>
      </div>
      <input :value="value" type="text" :class="`form-control border-left-0 border-${classColorName}`" @change="changeVal" :placeholder="$t('modules.process.process.template.addons.rating.placeholder')" />
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-rating',
  props: ['value', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
</style>
