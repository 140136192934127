<!-- Component Places vue  -->
<template>
  <div class="input-group mb-2 cursor-pointer place-input">
    <div class="input-group-prepend">
      <span class="input-group-text" data-toggle="tooltip" :title="$t('todos.location')"><i class="icon-location4"></i></span>
    </div>
    <places
      :options="{
        type:'address',
        templates: {
          value: function(suggestion) {
            return suggestion.name;
          }
        }}"
      v-model="data"
      @change="positionChange($event)"
      class="form-control form-control-lg bg-white px-2 algolia-form-control"
      :placeholder="$t('todos.location')"
    >
    </places>
  </div>
</template>

<script>

import Places from 'vue-places'
import { ACTION_REQUEST_ACTIONS_UPDATE } from '../../../mutations-types'
import store from '@/store'
/**
 * @group _ Module Actionsboard
 * This component display place input
 */
export default {
  name: 'PlacesTodo',
  props: ['position', 'id'],
  data () {
    return {
      data: null,
      worldId: store.getters['auth/getWorld'].id
    }
  },
  components: {
    Places
  },
  mounted () {
    this.data = this.position
  },
  methods: {
    /**
     * @vuese
     * Get position and update Actions
     * @arg object
     */
    positionChange (e) {
      let object = { value: e.name, id: this.id, key: 'place' }
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
      this.$store.commit('actionsboard/refreshTodo', object)
      })
    }
  }
}
</script>

<style lang="scss">
.place-input {
  .ap-input-icon{
    &.ap-icon-pin {
      display: none!important;
    }
  }
}
.algolia-form-control {
  padding-top: 1.125rem !important;
  padding-bottom: 1.125rem !important;
}
</style>
