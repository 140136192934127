<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">
      <el-select
        v-model="value"
        :options="config.options"
      />
    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <div class="d-flex align-items-center">
        <el-avatar :src="completeValue.avatar" />
        <span class="px-1 font-weight-semibold">{{ completeValue.title }}</span>
        <span>— {{ completeValue.detail }}</span>
      </div>

    </template>

  </div>
</template>

<script>

import Field from './_Field'
import _ from 'lodash'

export default {
  name: "ModuleFormFieldReference",
  extends: Field,
  components: {
  },
  data () {
    return {
      clonedValue: null,
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
    completeValue () {
      if (!this.value) {
        return null
      } else {
        let foundIndex = _.findIndex(this.config.options, e => { return e.value == this.value })
        if (!foundIndex == -1) {
          return null
        }
        return this.config.options[foundIndex]
      }
    },
  },
  watch: {
    value (newVal) {
      if (newVal) {
        this.clonedValue = newVal
      } else {
        this.clonedValue = null
      }
    },
    clonedValue (newVal) {
      this.changeVal(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
