<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <TextareaAutosize
        :value="value"
        :required="required"
        class="form-control"
        :id="id"
        @change.native="changeVal"
        :placeholder="placeholder"
      />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <div v-html="nl2br(value)" />

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldTextarea",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
