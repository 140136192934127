let fields = {
    'module-form-field-address': require('@/components/module/fields/Address.vue').default,
    'module-form-field-color': require('@/components/module/fields/Color.vue').default,
    'module-form-field-checkbox': require('@/components/module/fields/Checkbox.vue').default,
    'module-form-field-checkboxes': require('@/components/module/fields/Checkboxes.vue').default,
    'module-form-field-date': require('@/components/module/fields/Date.vue').default,
    'module-form-field-email': require('@/components/module/fields/Email.vue').default,
    'module-form-field-file': require('@/components/module/fields/File.vue').default,
    'module-form-field-heading': require('@/components/module/fields/Heading.vue').default,
    'module-form-field-image': require('@/components/module/fields/Image.vue').default,
    'module-form-field-number': require('@/components/module/fields/Number.vue').default,
    'module-form-field-phone': require('@/components/module/fields/Phone.vue').default,
    'module-form-field-radios': require('@/components/module/fields/Radios.vue').default,
    'module-form-field-rate': require('@/components/module/fields/Rate.vue').default,
    'module-form-field-select': require('@/components/module/fields/Select.vue').default,
    'module-form-field-reference': require('@/components/module/fields/Reference.vue').default,
    'module-form-field-separator': require('@/components/module/fields/Separator.vue').default,
    'module-form-field-tags': require('@/components/module/fields/Tags.vue').default,
    'module-form-field-text': require('@/components/module/fields/Text.vue').default,
    'module-form-field-textarea': require('@/components/module/fields/Textarea.vue').default,
    'module-form-field-url': require('@/components/module/fields/Url.vue').default,
    'module-form-field-wysiwyg': require('@/components/module/fields/Wysiwyg.vue').default,
    'module-form-field-user': require('@/components/module/fields/User.vue').default,
    'module-form-field-actions': require('@/components/module/fields/Actions.vue').default,
    'module-form-field-formpart': require('@/components/module/fields/Formpart.vue').default,
    'module-form-field-dropfile': require('@/components/module/fields/Dropfile.vue').default,
    'module-form-field-info': require('@/components/module/fields/Info.vue').default,
    'module-form-field-rrule': require('@/components/module/fields/Rrule.vue').default,
    'module-form-field-autocomplete': require('@/components/module/fields/Autocomplete.vue').default,
    'module-form-field-time': require('@/components/module/fields/Time.vue').default,
}

export default fields
