<template>

  <div>

    <div class="w-100">
      <div :class="`badge badge-${classColorName} mb-1`" v-tooltip="$t('modules.process.process.template.addons.selectlist.placeholder')">{{ $t('modules.process.process.template.addons.selectlist.name') }}</div>
      <textarea-autosize
        :value="value"
        type="text"
        class="form-control w-100"
        @blur.native="changeVal"
        :placeholder="$t('modules.process.process.template.addons.selectlist.placeholder')"
        :max-height="300"
      />
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-selectlist',
  props: ['value', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
</style>
