<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <input :required="required" :value="value" type="text" class="form-control w-100" @change="changeVal" :placeholder="placeholder" />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      {{ value }}

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  extends: Field,
  name: "ModuleFormFieldText",
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
