<template>
    <div class="mb-3 card" id="newTask" data-toggle="tooltip">
      <div class="input-group">
        <span class="flex">
          <div class="priority-dropdown-link-wrapper position-relative flex-item">
            <a href="#" :class="'priority-dropdown-link dropdown-toggle caret-0 d-block text-' + priorities[TaskToDo.taskPriority].class" data-toggle="dropdown">
              <i :class="priorities[TaskToDo.taskPriority].icon"></i>
            </a>

            <div class="dropdown-menu py-1">
              <a href="#" class="dropdown-item" v-for="(priority, index) in priorities" :key="index" @click="prioritiseNewTask(index, $event)">
                <i :class="priority.icon + ' icon-hover-white text-' + priority.class"></i>
                {{ priority.title }}
              </a>
            </div>
          </div>
        </span>

        <TaskTitle :title="TaskToDo.taskTitle" @create="typeTask" @leaveInput="checkInput" @limitInput="limitInput" :id="0" @desibleSubmit="desibleSubmit"></TaskTitle>

        <div class="date d-flex align-items-center justify-content-center text-center">
          <v-date-picker
            mode='range'
            :locale="$i18n.locale"
            v-model='selectedDate'
            :popover="{ placement: 'left', visibility: 'click' }"
            @drag="dragValue = $event"
            :select-attribute="selectDragAttribute"
            :drag-attribute="selectDragAttribute"
          >
            <div slot-scope='props' class="">
              <div
                v-html="formatSelectedDate(props.inputValue)"
                contenteditable="true"
                :class="{ ' py-0 cursor-pointer form-control form-control-lg bg-white px-1': true }"
                :placeholder="$t('todos.due_date_placeholder')"
                @change='props.updateValue($event.target.value)'
              >
              </div>
            </div>
            <div slot="day-popover" slot-scope="{ format }">
              <span v-if="format(dragValue ? dragValue.start : range.start, 'D MMM') != format(dragValue ? dragValue.end : range.end, 'D MMM')">
                {{ format(dragValue ? dragValue.start : range.start, 'D MMM') }}
                →
                {{ format(dragValue ? dragValue.end : range.end, 'D MMM') }}
              </span>
              <div v-else>
                {{ $t('date.picker.select_help') }}
              </div>
            </div>
          </v-date-picker>
        </div>

        <TaskResponsible v-show="currentWorld.pivot.internal" :type="'create'" :users="users" @responsibleTask="responsibleTask" :owner="TaskToDo.owner" :creator="null"></TaskResponsible>

<!--         <div class="input-group-append">
          <label class="input-group-text bg-white text-yellow cursor-pointer">
            <input type="checkbox" v-model="TaskToDo.taskFavorite" class="d-none" id="newTaskFavorite" @click="newTaskFavorite($event)">
            <i class="icon-star-empty3" v-if="!TaskToDo.taskFavorite"></i>
            <i class="icon-star-full2" v-if="TaskToDo.taskFavorite"></i>
          </label>
        </div> -->
        <button v-if="!loading_submit" :disabled="disabledBtn" class="btn btn-lg btn-success text-white btn-title-primiry" @click="addTask($event, true)">
          <i class="icon-arrow-right8"></i>
        </button>
        <div v-if="loading_submit" class="bg-white p-2">
          <span class="text-primary"><loader></loader></span>
        </div>
      </div>
      <div class="px-3 pt-3" v-if="!statusTitle">
        <el-alert type="warning">
          <span> {{ $t(module+'.todos.title_vide') }} </span>
        </el-alert>
      </div>
      <div class="px-3 pt-3" v-if="statusNbrChar && statusTitle">
        <el-alert type="warning">
          <span> {{ $t(module+'.todos.title_limt_char') }} </span>
        </el-alert>
      </div>
    </div>
</template>

<script>

import store from '@/store'
import {
ACTION_REQUEST_ACTIONS_CREATOR,
ACTION_REQUEST_USERS,
ACTION_REQUEST_ACTIONS_STORE,
ACTION_ACTIVE_OWNER,
ACTION_STORE_TAGS,
ACTION_REQUEST_ACTIONS_UPDATE,
ACTION_REQUEST_TAGS
} from '../../mutations-types'

import TaskTitle from './_TaskTitle'
import TaskResponsible from './_TaskResponsible'
import moment from 'moment'
/**
 * @group _ Module Actionsboard
 * This component dfor create action
 */
export default {
  name: "TodoCreate",
  props: {
    formatSelectedDate: {
      type: [Object, Function],
    },
    priorities: {
      type: Object,
    },
    users: {
      type: [Object, Array],
    },
    dataId: {
      type: Number,
      default: null,
    },
    addCallback: {
      type: Function,
    },
  },
  components: {
    TaskTitle,
    TaskResponsible
  },
  data () {
    return {
      dragValue: null,
      selectedDate: null,
      taskResponsible: null,
      filters: [],
      TaskToDo : {
        taskTitle: '',
        taskDate: '',
        taskFavorite: false,
        taskPriority: 'none',
        userTaskOwner: null,
        worldId: store.getters['auth/getWorld'].id,
        owner: null,
        done: false,
        dueDate: '',
        timeToDo: null,
        reminderDate: '',
        place: null,
        project: [],
        tribus: [],
        tag: [],
        list: [],
        model: [],
        description: null,
        comment: null,
        source: 'local',
        recurrence: null,
        langue: 'fr',
        position: null,
        dataId: this.dataId,
      },
      statusTitle: true,
      module: 'modules.'+this.$route.meta.moduleName,
      statusNbrChar: false,
      loading_submit: false,
      disabledBtn: true,
      createTag: [],
      idTags: []
    }
  },
  mounted () {
    this.TaskToDo.owner = this.currentUser.id
  },
  computed: {
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false, // Defaults to true when using slot
        },
      }
    },
  },
  methods: {
    /**
     * @vuese
     * Check that event is Enter to store action
     * @arg
     */
    typeTask (e, status = false) {
      this.statusTitle = status
      if(e != null) {
        e.preventDefault()
        if (e.key === 'Enter' && status) {
          this.addTask(e)
          return false
        }
      }
    },
    /**
     * @vuese
     * Add task into the list create
     * @arg
     */
    addTask (e, status = false) {
      e.preventDefault()
      this.loading_submit = status
      let theOwner = (this.TaskToDo.owner !== null && typeof this.TaskToDo.owner !== 'undefined') ? this.TaskToDo.owner : null
      let newToDoTask = {
        name: $('.taskSelectedIs_0').html(),
        startDate: this.selectedDate && this.selectedDate.start ? moment(this.selectedDate.start).format() : null,
        endDate: this.selectedDate && this.selectedDate.end ? moment(this.selectedDate.end).format() : null,
        pdca: 'plan',
        priority: this.TaskToDo.taskPriority,
        worldId: this.TaskToDo.worldId,
        owner: theOwner,
        favorite: this.TaskToDo.taskFavorite,
        creator: null,
        done:false,
        dueDate: null,
        timeToDo: null,
        reminderDate: this.selectedDate && this.selectedDate.start ? moment(this.selectedDate.start).format() : null,
        place: null,
        project: this.TaskToDo.project,
        tribus: this.TaskToDo.tribus,
        tag: this.TaskToDo.tag,
        list: this.TaskToDo.list,
        model: this.TaskToDo.model,
        description: null,
        comment: null,
        source: 'local',
        recurrence: null,
        langue: 'fr',
        ownerActivated: false,
        actionOrigin: null,
        actionRecurrence: false,
        position: null,
        dataId: this.dataId,
      }

      let input = $('.taskSelectedIs_0')
      let inputAfterCheck = input.text()

      if ($.trim(inputAfterCheck) !== '' && inputAfterCheck.length <= 200) {

          let el = $('.taskSelectedIs_0').find('[type="tags"]')
          let newTagsCreated = []
          if(el.length > 0) {
            let app = this
            el.each(function () {
              if($(this).attr('index') == 0) {
                app.createTag.push($(this).attr('name'))
              }
            })
          }

        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_STORE, {id: this.TaskToDo.worldId, entity: 'Actions', action: newToDoTask}).then(resp => {
          if(this.createTag.length > 0) {
            this.createTag.map(async (item, key) => {
              let object = {
                name: item,
                type: 'Tag',
                worldId: this.TaskToDo.worldId
              }
              this.$store.dispatch('actionsboard/' + ACTION_STORE_TAGS, {id: this.TaskToDo.worldId, entity: 'Tags', type: 'Tag', tag: object}).then(value => {
                $('.taskSelectedIs_'+resp[1][0].id).find('[type="tags"]').each(function () {
                  if($(this).attr('name') == object.name && $(this).attr('index') == 0 ) {
                    $(this).attr('index', value[1][0].id)
                  }
                })
                this.idTags.push(value[1][0].id)
                if(this.createTag.length == key +1) {
                  store.dispatch('actionsboard/' + ACTION_REQUEST_TAGS, {id: store.getters['auth/getWorld'].id, entity: 'Tags', type: 'all'}).then(tags => {
                  })
                  this.updateAction({id: resp[1][0].id, key: 'tag', value: this.idTags })
                  let title = $('.taskSelectedIs_'+resp[1][0].id).html()
                  this.updateAction({id: resp[1][0].id, key: 'name', value: title })
                }
              })
            })
          }
          this.$store.commit('actionsboard/pushTodos', resp[1])
          if (this.addCallback) {
            this.addCallback(resp[1][0])
          }
          this.notifSuccess(resp[0])
          $('.taskSelectedIs_0').empty()
          this.TaskToDo = {
            taskTitle: '',
            taskDate: '',
            taskFavorite: false,
            taskPriority: 'none',
            userTaskOwner: null,
            worldId: this.TaskToDo.worldId,
            owner: null,
            done:false,
            dueDate: '',
            timeToDo: null,
            reminderDate: '',
            project: [],
            tribus: [],
            tag: [],
            list: [],
            model: [],
            description: null,
            comment: null,
            source: 'local',
            recurrence: null,
            langue: 'fr',
            position: null
          }

        })
        .catch(error => {
          this.notifError(error)
          this.disabledBtn = false
        })
      } else {
          this.notifError('error')
      }
      this.loading_submit = false
      this.disabledBtn = true
    },
    /**
     * @vuese
     * Set the new task priority
     * @arg
     */
    prioritiseNewTask (priotity, e) {
      e.preventDefault()
      this.TaskToDo.taskPriority = priotity
    },
    /**
     * @vuese
     * set action to favourite
     * @arg
     */
    newTaskFavorite (e) {
      e.preventDefault()
      this.TaskToDo.taskFavorite = !this.TaskToDo.taskFavorite
    },
    /**
     * @vuese
     *  set responsible Action
     * @arg
     */
    responsibleTask(user) {
      this.TaskToDo.owner = user
      let el = '.taskSelectedIs_0'
      if ($(el).find('[type="users"]').length > 0 && $(el).find('[type="users"]').attr('index') != user) {
        $(el).find('[type="users"]').remove()
        $(el).html(function (i, html) {
          return html.replace(/&nbsp;/g, '');
        });
      }
    },
    /**
     * @vuese
     * add tag when i update by tagData component
     * @arg
     */
    AddTagCreate (data) {
      this.TaskToDo[data.type] = data.id
    },
    /**
     * @vuese
     *  check titile
     * @arg
     */
    //checkInput leaveInput
    checkInput() {
      this.statusTitle = true
    },
    /**
     * @vuese
     * limit title action
     * @arg
     */
    // check limit char
    limitInput (status) {
      this.statusNbrChar = status
    },
    /**
     * @vuese
     *  this function controlle btn validate action to create if tltle empty or not
     * @arg
     */
    desibleSubmit (status) {
      this.disabledBtn = status
    },
    /**
     * @vuese
     *  this function update actions and refresh store
     * @arg
     */
    updateAction (object) {
      this.$store.dispatch('actionsboard/' + ACTION_REQUEST_ACTIONS_UPDATE, {
        id: this.TaskToDo.worldId,
        entity: 'Actions',
        action: object
      }).then((resp) => {
        this.$store.commit('actionsboard/refreshTodo', object)
      })
    }
  }
}
</script>


<style lang="scss" scoped>
  .icon-sort {
    font-size: 7px;
    transform: scale(1.8);
    padding: 0 3px;
  }
  #newTaskTitle {
    max-height: 40px;
    overflow: hidden;
  }
  .text-striked {
    text-decoration:line-through;
  }
  .input-group {
    .form-control,
    label {
      border-color: white;
    }
    span {
      input {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &.date {
        width: 100px;
        input {
          text-align: center;
        }
      }
    }
  }
  #newTask .priority-dropdown-link-wrapper  {
    border-radius: 4px 0 0 4px;
  }
  .priority-dropdown-link {
    padding: 9px 11px;
  }
  [contenteditable=true] {
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }
  body.navbar-top .form-control {
   border-width: 1px;
 }
 .input-group-text{
   border: 1px solid #ddd;
 }
 .flex-item {
   display: flex;
   align-items: center;
 }
 .flex {
   display: flex;
 }
 .flex-date{
   display: flex;
   width: 105px
 }
 .btn-title-primiry {
   border-radius: 4px !important;
 }
  .form-control:focus[contenteditable="true"] {
    box-shadow: none !important;
    border-color: white !important;
  }
</style>
