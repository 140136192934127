<template>

  <div :id="'step-' + id">

    <div class="w-100 d-flex align-items-center">
      <i :class="`icon-arrow-right8 pt-1`"></i>
      <input :value="value" type="text" :class="`text-${classColorName} border-0 font-weight-bold px-1 py-0 form-control form-control-discreet w-100`" @change="changeVal" :placeholder="$t('modules.process.process.template.addons.step.placeholder')" />
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-step',
  props: ['value', 'id', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
i {
  font-size: 1em;
}
.form-control {
  font-size: 1.8em;
}
</style>
