<template>
  <div>

    <div v-if="process">
      <div class="small text-grey text-uppercase font-weight-bold">{{ process.world.name }}</div>
      <h1>{{ process.data.name }}</h1>
      <hr>
    </div>
    <div v-if="!completed">
      <div class="text-center py-5" v-if="!process">
        <loader />
      </div>
      <div v-else>
        <div class="mb-2 pl-3">
          <label for="emailfield" class="mb-1 font-weight-bold">{{ $t('common.email') }}</label>
          <input v-model="process.data.email" type="email" class="form-control" id="emailfield" :placeholder="$t('common.email')">
        </div>
        <process-entity
          :process="process"
          classColorName="success"
        />
        <div class="px-3 my-2">
          <vue-recaptcha
            ref="recaptcha"
            @verify="captchaOnVerify"
            @expired="captchaOnExpired"
            sitekey="6LdwM-cUAAAAAH4nYwHDhCRWk39VPZJwcMR6c7Bb"
            theme="light"
            :loadRecaptchaScript="true"
          />
        </div>
        <div class="text-center">
          <button :class="'my-2 mx-3 px-3 btn btn-success btn-lg'" :disabled="!captchaVerified || saving" @click="saveContent()">
            <span v-if="!saving">
              {{ $t('common.save') }}
            </span>
            <loader v-else />
          </button>
        </div>
      </div>
    </div>
    <div v-else class="mt-4 pt-1">
      <el-alert type="success">
        {{ $t('modules.process.form.public.completed') }}
      </el-alert>
    </div>

  </div>
</template>

<script>

import VueRecaptcha from 'vue-recaptcha';
import store from '@/store'

import {
  PROCESS_GET_PROCESS,
  PROCESS_UPDATE_PROCESS,
  PROCESS_CREATE_FORM,
} from '../mutations-types'

import {
  MODULE_GET_BY_PUBLICKEY,
} from '@/store/mutations-types'

export default {
  components: {
    VueRecaptcha
  },
  data () {
    return {
      captchaVerified: false,
      completed: false,
      saving: false,
      loading: true,
      process: null,
    }
  },
  mounted () {
  },
  beforeRouteEnter (to, from, next) {
    store.dispatch('module/' + MODULE_GET_BY_PUBLICKEY, {
      worldId: to.params.world,
      publickey: to.params.publickey,
    }).then(data => {
      next(vm => {
        vm.loading = false
        vm.process = {
          module: 'process',
          entity: 'Process',
          user_id: 1, // TODO null
          world_id: to.params.world,
          world: data.world,
          data: {
            name: data.data.name,
            processValues: [],
            processTemplateContent: data.data.content,
            processTemplate: data.id,
          },
        }
      })
    })
    .catch(error => {
      next(vm => {
        vm.loading = false
        vm.notifError(error)
      })
    })
  },
  methods: {
    onSubmit: function () {
    },
    captchaOnVerify: function (response) {
      this.captchaVerified = true
    },
    captchaOnExpired: function () {
      this.captchaVerified = false
    },
    saveContent () {
      // this.$refs.recaptcha.execute()
      this.saving = true
      this.$store.dispatch('process/' + PROCESS_CREATE_FORM, {
        worldId: this.$route.params.world,
        data: {
          name: this.process.data.name,
          email: this.process.data.email,
          processTemplate: this.process.data.processTemplate,
          processValues: this.process.data.processValues,
        }
      }).then(process => {
        this.completed = true
      })
      .catch(error => {
        this.saving = false
        this.notifError(error)
      })
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
</style>
