<template>

  <div>
      <h2
        :class="{
          'mt-4': index > 0,
          'mb-2 font-weight-semibold text-info': true,
        }"
      >
        {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}`) }}
      </h2>
  </div>

</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldHeading",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
