<!-- Component show erreurs for action -->
<template>
  <el-alert type="warning" class="warning-text" v-if="statusTitleError || statusNbrCharError">
    <span v-if="statusTitleError"> {{ $t(module+'.todos.title_vide') }} </span>
    <span v-if="statusNbrCharError"> {{ $t(module+'.todos.title_limt_char') }} </span>
  </el-alert>
</template>

<script>
/**
 * @group _ Module Actionsboard
 * This component display error message
 */
export default {
  name: 'ErrorElement',
  /**
  * @vuese
  * get statusTitle is empty or not and statusNbrChar get check length text in input
  */
  props: ['statusTitle', 'statusNbrChar'],
  data () {
    return {
      module: 'modules.'+this.$route.meta.moduleName,
      statusTitleError: false,
      statusNbrCharError: false
    }
  },
  mounted() {
    this.statusNbrCharError = this.statusNbrChar
    this.statusTitleError = this.statusTitle
  }
}
</script>

<style lang="css" scoped>
.warning-text {
  width: 30%;
  margin-left: 44px;
}
</style>
