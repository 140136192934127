<template>
  <div
    :class="done == true ? 'form-control px-2 form-control-lg border-right-0 text-striked newTaskTitle_ newTaskTitle_'+id + ' taskSelectedIs_'+id :'form-control px-2 form-control-lg border-right-0 newTaskTitle_ newTaskTitle_'+id + ' taskSelectedIs_'+id  "
    contentEditable="true"
    type="text"
    name="title"
    @keyup.enter="typeTask($event, id)"
    @click="titlePlaceholder = ''"
    @blur="leaveInput()"
    :placeholder="titlePlaceholder"
    @input="getUserInTitle($event)"
    :data-item="id"
    v-html='title'
  >
  </div>
</template>
<script>

const $ = require('jquery')
window.jQuery = $;
window.$ = $;
import {ACTION_REQUEST_ACTIONS_UPDATE} from '../../mutations-types'
import store from '@/store'
/**
 * @group _ Module Actionsboard
 * This component display input (contenteditable) actions title
 */
export default {
  name: "TaskTitle",
  /**
  * @vuese
  * title = string (Html) , id: integer , done : Boolean
  */
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
    editCallback: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      taskTitle: null,
      keys: {
        users: '@',
        date: '^',
        tags: '#'
      },
      status: true,
      titlePlaceholder: this.$t('todos.new'),
      worldId: store.getters['auth/getWorld'].id,
      statusTitle: false
    }
  },
  mounted () {
    let app = this
    this.taskTitle = this.title != 'undefined' ? this.title : null
    this.id = this.id != 'undefined' ? this.id : 0
    let $editables = $('[contenteditable=true]');

    $editables.filter("div").on('keypress',function(e){
    // delete creation div br whene i click on Entre in div contentEditable
     if(e.keyCode == 13){
      e.preventDefault();
      if (window.getSelection) {
          var selection = window.getSelection(),
          range = selection.getRangeAt(0);
          range.deleteContents();
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
          return false;
        }
      }
    });
    this.limtChar()
  },
  methods: {
    /**
     * @vuese
     * This function execut with change title o show component TagData
     * @arg
     */

    getUserInTitle (e) {
      e.preventDefault()
      if (this.id == 0 && $('.taskSelectedIs_0').text().length > 0) {
        this.$emit('desibleSubmit', false)
      } else if (this.id == 0 && $('.taskSelectedIs_0').text().length == 0) {
        this.$emit('desibleSubmit', true)
      }
      if (e.key !== 'Enter') {
        let el = $('.newTaskTitle_'+this.id)
        let text = $('.newTaskTitle_'+this.id).text()
        let coords = this.getSelectionCoords(e)
        let char = text[text.length-1]
        if (
          text.length > 0 &&
          (
            char === this.keys['users'] ||
            // char === this.keys['date'] ||
            char === this.keys['tags']
          ) && this.status
        ) {
          let tagType = Object.keys(this.keys)[Object.values(this.keys).indexOf(char)];
          this.$store.commit('actionsboard/changeTypeTag',tagType)
          let div = $(el).attr('data-item')
          let selector = 'taskSelectedIs_' + div
          $('#tag-autocomplete').css({
            'left': (coords.x) + 'px',
            'top': (coords.y) + 'px'
          }).show().find('input[type="text"]').focus()
          $('input[name="element-selected"]').val(selector)
        } else {
          $('#tag-autocomplete').hide()
          $('input[name="element-selected"]').val('')
          this.status = true
        }
      }

    },
    /**
     * @vuese
     * This function get coords cursor when i taping title action (create and update)
     * @arg
     */

    getSelectionCoords(e, win) {
      e.preventDefault()
      win = win || window;
      var doc = win.document;
      var sel = doc.selection, range, rects, rect;
      var x = 0, y = 0;
      if (sel) {
          if (sel.type != "Control") {
              range = sel.createRange();
              range.collapse(true);
              x = range.boundingLeft;
              y = range.boundingTop;
          }
      } else if (win.getSelection) {
          sel = win.getSelection();
          if (sel.rangeCount) {
              range = sel.getRangeAt(0).cloneRange();

              if (range.getClientRects) {
                  range.collapse(true);
                  rects = range.getClientRects();

                  if (rects.length > 0) {
                      rect = rects[0];
                  }

                  if(typeof rect != "undefined") {
                    x = rect.left;
                    let heightElemenet = typeof $('.message-global').height() == "undefined" ? 0 : $('.message-global').height()
                    y = $('.newTaskTitle_'+this.id).offset().top - heightElemenet //rect.top
                  } else {
                    x = 0;
                    y = 0;
                  }
              }
          }
      }

      x = x - 64;
      if($('.sidebar-mobile-main').length !== 0){
        x = $('.sidebar-mobile-main').width() - 160;
      }
      return {x: x, y: y - 165}

    },
    /**
     * @vuese
     *  This function execute when detect on of items in object : keys: {
    *         users: '@',
    *         date: '^',
    *         tags: '#'
    *     }
    * @arg
    */

    typeTask(e, id) {
      e.preventDefault()
      if (e.key === 'Enter' && id == 0) {
        this.statusTitle = false
        let title = $('.taskSelectedIs_0').html()
        if($.trim($('.taskSelectedIs_0').text()) !== '') {
          this.$emit('create', e, true)
        } else {
          this.statusTitle = false
          this.$emit('create', e, this.statusTitle)
        }
      }
    },
    /**
     * @vuese
     * this function execut when i leave div contentEditable to update title action (don t work on create)
     * @arg
     */

    leaveInput () {
      if(this.id !== 0) {
        let input = $('.newTaskTitle_'+this.id)
        let title = input.html()
        let inputAfterCheck = input.text()
        let len = inputAfterCheck.length
        if(len > 0 && len < 101) {
          let object = {
            value: title,
            key: 'name',
            id:this.id
          }

          this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
            $('.detail-name-'+app.id).html(object.value)
            this.$store.commit('actionsboard/refreshTodo', object)
            if (this.editCallback) {
              this.editCallback(object.id, object.key, object.value)
            }
          })
        }
      }
      if($.trim($('.taskSelectedIs_'+ this.id).html()) === '') {
        this.titlePlaceholder = this.$t('todos.new')
      }
      this.$emit('leaveInput')
    },
    /**
     * @vuese
     *  This function controlle length char in div contentEditable title
     *
     */

    limtChar () {
      let app = this
      let settings = {
        maxLen: 200,
      }

      let keys = {
        'backspace': 8,
        'shift': 16,
        'ctrl': 17,
        'alt': 18,
        'delete': 46,
        'leftArrow': 37,
        'upArrow': 38,
        'rightArrow': 39,
        'downArrow': 40,
      }

      let utils = {
        special: {},
        navigational: {},
        isSpecial(e) {
          return typeof this.special[e.keyCode] !== 'undefined';
        },
        isNavigational(e) {
          return typeof this.navigational[e.keyCode] !== 'undefined';
        }
      }

      utils.special[keys['backspace']] = true;
      utils.special[keys['shift']] = true;
      utils.special[keys['ctrl']] = true;
      utils.special[keys['alt']] = true;
      utils.special[keys['delete']] = true;

      utils.navigational[keys['upArrow']] = true;
      utils.navigational[keys['downArrow']] = true;
      utils.navigational[keys['leftArrow']] = true;
      utils.navigational[keys['rightArrow']] = true;
      $('.newTaskTitle_'+this.id).on('keydown', function(event) {
        let len = event.target.innerText.trim().length;

        let hasSelection = false;
        let selection = window.getSelection();
        let isSpecial = utils.isSpecial(event);
        let isNavigational = utils.isNavigational(event);

        if(len == 0) {
          app.$emit('limitInputExistanceEmpty', true, $(this).data('item'), true)
        } else {
          app.$emit('limitInputExistanceEmpty', false, $(this).data('item'), false)
        }

        if (selection) {
          hasSelection = !!selection.toString();
        }
        if (isSpecial || isNavigational) {
          if(len < settings.maxLen) {
            $(this).data('item') != 0 ? app.$emit('limitInputExistance', false, $(this).data('item'), false): app.$emit('limitInput', false)
          }
          return true;
        }

        if(len >= settings.maxLen ) {
          $(this).data('item') != 0 ? app.$emit('limitInputExistance', true, $(this).data('item'), true) : app.$emit('limitInput', true)
        }

        if (len >= settings.maxLen && !hasSelection) {
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.detailsIsShow {
  border-color: rgba(247, 124, 75, 0.5) !important;
  box-shadow: 0 0 0 0.2em rgba(247, 124, 75, 0.25) !important;
}

[placeholder]:empty::before {
    content: attr(placeholder);
    color: #AAA;
}

[placeholder]:empty:focus::before {
    content: "";
}
</style>
