<template>
  <div :class="{'card mb-2': true, 'hovered': hovered, 'not-hovered': !hovered}" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <div class="input-group">
      <div class="input-group-prepend">
        <label class="input-group-text bg-white curclickOutsideTasksor-pointer pr-2 cursor-pointer" @mouseenter="checkHovered = true" @mouseleave="checkHovered = false">
          <input class="d-none" type="checkbox" :checked="item.done" @change="checkTask(item, $event)">
          <i class="icon-checkbox-unchecked2" :class="{ 'text-success': checkHovered }" v-if="!item.done" />
          <i class="icon-checkbox-checked2 text-success" v-if="item.done" />
        </label>
      </div>
      <span class="flex">
        <div class="priority-dropdown-link-wrapper position-relative flex-item">
          <a href="#" :class="'priority-dropdown-link dropdown-toggle caret-0 d-block text-' + priorities[item.priority].class" data-toggle="dropdown">
            <i :class="priorities[item.priority].icon" />
          </a>

          <div class="dropdown-menu py-1">
            <a v-if="hovered" href="#" class="dropdown-item" v-for="(priority, priorityindex) in priorities" :key="priorityindex" @click="prioritiseTask(item, priorityindex, $event)">
              <i :class="priority.icon + ' icon-hover-white text-' + priority.class" />
              {{ priority.title }}
            </a>
          </div>
        </div>
      </span>

      <span class="flex">
        <div class="pdca-dropdown-link-wrapper position-relative flex-item">
          <a href="#" :class="'pdca-dropdown-link dropdown-toggle caret-0 d-block'" data-toggle="dropdown">
            <Pdca :value="item.pdca || 'plan'" />
          </a>

          <div class="dropdown-menu py-1">
            <a v-if="hovered" href="#" class="dropdown-item" v-for="pdcaindex in pdca_values" :key="pdcaindex" @click="pdcaTask(item, pdcaindex, $event)">
              <Pdca :value="pdcaindex" />
              <span class="ml-2">
                {{ $t(module + '.pdca.' + pdcaindex) }}
              </span>
            </a>
          </div>
        </div>
      </span>

      <span
        class="flex py-2 ml-3 h-100 align-items-center assigned-by"
        v-if="item.oldOwner && item.oldOwner != item.owner && item.oldOwner != currentUser.id && userById(item.oldOwner)"
        v-tooltip="$t(module + '.assigned_by', {name: userById(item.oldOwner).rendered_name })"
      >
        <i class="icon-loop small"/>
        <span class="avatar-wrap"><el-avatar v-if="userById(item.oldOwner)" :src="userById(item.oldOwner).avatar.thumb" size="xsmall" /></span>
      </span>

      <TaskTitle
        :title="item.name"
        :id="item.id"
        :done="item.done"
        @limitInputExistanceEmpty="limitInputExistanceEmpty"
        @limitInputExistance="limitInputExistance"
        @click.native="selectTaskDetails(item)"
        :editCallback="editCallback"
      />

      <span class="d-flex align-items-center" v-if="item.process && item.process != ''">
        <div>
          <router-link class="btn btn-greylight btn-sm rounded border-0 cursor-pointer" tag="span" :to="{name: 'process.process.page', params: { world: this.$route.params.world, process: item.process}}">
            <i :class="'text-primary icon-' + $router.resolve({name: 'process.template.index', params: { world: this.$route.params.world}}).route.meta.icon" />
            {{ $t(module + '.view_process') }}
          </router-link>
        </div>
      </span>

      <span class="d-flex mr-1" v-if="item.url && item.url != ''">
          <a :href="item.url" class="link-action text-dark" target="_new">
            <i class="icon-link" />
          </a>
      </span>

      <div class="w-100 d-md-none"></div>

      <div class="pl-2 pl-md-0 date d-flex align-items-center justify-content-center text-center">
        <v-date-picker
          mode='range'
          :locale="$i18n.locale"
          v-model='selectedDate'
          :popover="{ placement: 'left', visibility: 'click' }"
          @drag="dragValue = $event"
          :select-attribute="selectDragAttribute"
          :drag-attribute="selectDragAttribute"
        >
          <div slot-scope='props' class="">
            <div
              v-html="formatSelectedDate(props.inputValue)"
              contenteditable="true"
              :class="{ ' py-0 cursor-pointer form-control form-control-lg bg-white px-1': true, ' text-striked': item.done }"
              :placeholder="$t('todos.due_date_placeholder')"
              @change='props.updateValue($event.target.value)'
            >
            </div>
          </div>
          <div slot="day-popover" slot-scope="{ format }">
            <span v-if="format(dragValue ? dragValue.start : range.start, 'D MMM') != format(dragValue ? dragValue.end : range.end, 'D MMM')">
              {{ format(dragValue ? dragValue.start : range.start, 'D MMM') }}
              →
              {{ format(dragValue ? dragValue.end : range.end, 'D MMM') }}
            </span>
            <div v-else>
              {{ $t('date.picker.select_help') }}
            </div>
          </div>
        </v-date-picker>
      </div>

      <span class="user flex-item" v-show="currentWorld.pivot.internal">
        <div class="user-dropdown-link-wrapper">
          <a href="#" class="text-grey user-dropdown-link px-2 dropdown-toggle caret-0 d-block" data-toggle="dropdown" v-if="userAuth.id == item.owner && item.owner != item.creator">
            <img :src="userAuth.avatar.thumb" class="user-img circle">
            <div class="user-badge text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="item.owner && userAuth && !userAuth.avatar.thumb">
              {{ getInitials(userAuth.first_name) !== ''? getInitials(userAuth.first_name): getInitials(userAuth.email) }}
            </div>
          </a>
          <a v-else href="#" class="text-grey user-dropdown-link px-2 dropdown-toggle caret-0 d-block" data-toggle="dropdown">
            <i class="icon-users pl-1" v-if="item.owner == -1" />
            <img :src="TaskUser(item.owner)[0].avatar.thumb" class="user-img circle" v-else-if="TaskUser(item.owner)">
            <div class="user-badge text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="item.owner && TaskUser(item.owner) && TaskUser(item.owner)[0] && !TaskUser(item.owner)[0].avatar.thumb">
              {{ TaskUser(owner)[0].first_name !== ''? getInitials(TaskUser(owner)[0].first_name): getInitials(TaskUser(owner)[0].email) }}
            </div>
          </a>

          <div class="dropdown-menu py-1" >
            <template v-if="hovered" v-for="(user, index) in users">
              <a href="#" class="dropdown-item" :key="index" @click="updateActionOwner(index, user.id, item, $event)">
                <img :src="user.avatar.thumb" class="user-img circle" v-if="user.avatar.thumb">
                <div class="user-badge mr-2 text-uppercase badge badge-light font-weight-bold circle bg-grey" v-if="!user.avatar.thumb">
                  {{ user.full_name !== '' ? getInitials(user.full_name): getInitials(user.email) }}
                </div>
                {{ user.full_name !== '' ? user.full_name: user.email }}
                <span class="badge badge-yellow ml-1 " v-if="!user.pivot.internal">{{ $t('common.external') }}</span>
              </a>
            </template>

<!--             <a href="#" class="dropdown-item" @click="updateActionOwner(null, -1, item, $event)">
              <i class="icon-users pl-1" />
              {{ $t(module+'.monde_all_users') }}
            </a> -->
            <div class="dropdown-divider"/>
            <a href="#" class="dropdown-item text-grey" @click="updateActionOwner(null, null, item, $event)">
              <i class="icon-user pl-1" />
              {{ $t('common.clear') }}
            </a>
          </div>
        </div>
      </span>
      <div class="input-group-append">
        <label class="input-group-text bg-white text-yellow cursor-pointer">
          <input type="checkbox" v-model="item.favorite" class="d-none" @change="favoriteChange($event)">
          <i class="icon-star-empty3" v-if="!item.favorite" />
          <i class="icon-star-full2" v-else />
        </label>
      </div>
    </div>
    <div v-if="errorTemplate.element && errorTemplate.id == errorTemplate.id">
      <ErrorElement :statusNbrChar='errorTemplate.limit' ></ErrorElement>
    </div>
    <div v-if="errorTemplateEmpty.element && errorTemplateEmpty.id == errorTemplateEmpty.id">
      <ErrorElement :statusTitle='errorTemplateEmpty.empty' ></ErrorElement>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import {ACTION_REQUEST_ACTIONS_UPDATE, ACTION_REQUEST_ACTIONS_UPDATE_DATE} from '../../mutations-types'
import TaskTitle from './_TaskTitle'
import sousComponents from './sousComponents'
import moment from 'moment'
/**
 * @group _ Module Actionsboard
 * This component display actions in listing
 */
export default {
  components: {
    TaskTitle,
    ErrorElement: sousComponents.ErrorElement,
    Pdca: sousComponents.Pdca
  },
  /**
  * @vuese
  *  item: object action , users: object
  */
  props: {
    selectTaskDetails: {
      type: [Object, Function],
    },
    formatSelectedDate: {
      type: [Object, Function],
    },
    item: {
      type: Object,
    },
    priorities: {
      type: Object,
    },
    pdca_values: {
      type: Array,
    },
    users: {
      type: [Object, Array],
    },
    dataId: {
      type: Number,
      default: null,
    },
    editCallback: {
      type: Function,
      default: null,
    },
  },
  data () {
    return {
      dragValue: null,
      checkHovered: false,
      hovered: false,
      mounted: false,
      responsible: null,
      userAuth: store.getters['auth/getProfile'],
      worldId: store.getters['auth/getWorld'].id,
      module: 'modules.' + this.$route.meta.moduleName,
      errorTemplate: {},
      errorTemplateEmpty: {}
    }
  },
  mounted () {
    let app = this
    setTimeout(() => {
      this.mounted = true
    }, 400)
  },
  computed : {
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    selectedDate: {
      get: function () {
        return {
          start: this.item.startDate ? moment(this.item.startDate).toDate() : null,
          end: this.item.endDate ? moment(this.item.endDate).toDate() : null,
        }
      },
      set: function (newValue) {
        if (this.mounted) {
          if (
            (newValue && newValue.start && moment(this.item.startDate).format() != moment(newValue.start).format()) ||
            (newValue && newValue.end && moment(this.item.endDate).format() != moment(newValue.end).format())
          ) {
            this.updateActionDate(
              this.item.id,
              moment(newValue.start).format(),
              moment(newValue.end).format(),
            )
          }
        }
      }
    },
    selectDragAttribute() {
      return {
        popover: {
          visibility: 'hover',
          isInteractive: false, // Defaults to true when using slot
        },
      }
    },
  },
  methods : {
    userById (searchId) {
      return this.users.find(user => { return user.id == searchId })
    },
    /**
     * @vuese
     * Get first char full name
     * @arg
     */
    getInitials (string) {
      let initials = ''
      string.split(' ').forEach(part => {
        initials += part.substring(0, 1)
      })
      return initials
    },
    /**
     * @vuese
     *  Check on unchcked task
     * @arg
     */
    checkTask (task, e) {
      this.$set(task, 'done', e.target.checked)
      let object = {id: this.item.id ,key: 'done', value: e.target.checked}
      this.updateActionElement(object, null)
      this.$store.commit('actionsboard/refreshTodo', object)
    },
    /**
     * @vuese
     * get user object
     * @arg
     */
    TaskUser (user) {
      if (this.users.length == 0) {
        return null
      }
      return this.users.filter(item => {
        if(item.id == user) {
          return item
        }
      })
    },
    /**
     * @vuese
     * Select responsable action
     * @arg object
     */
    updateActionOwner (responsible, user,item, e) {
      if(user == null) {
        user = item.creator
      }
      e.preventDefault()
      if(item.owner != user) {
        let object = {
          value: user,
          key: 'owner',
          id:item.id
        }
        let newUser = this.users.filter(value => {
          if(value.id === user) {
            return value
          }
        })

        item.owner = user

        let el = '.taskSelectedIs_'+item.id
        if ($(el).find('[type="users"]').length > 0 && $(el).find('[type="users"]').attr('index') != user) {
          $(el).find('[type="users"]').remove()
          $(el).html(function (i, html) {
            return html.replace(/&nbsp;/g, '');
          });
        }

        let objectTitle = {
          value: $('.taskSelectedIs_'+item.id).html(),
          key: 'name',
          id:item.id
        }
        this.$store.commit('actionsboard/refreshTodo', object)
        this.$store.commit('actionsboard/refreshTodo', objectTitle)
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: objectTitle}).then((resp) => {
        })
        this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
          // this.notifSuccess(resp)
          if (this.editCallback) {
            this.editCallback(object.id, object.key, object.value)
          }
        })
      }
    },
    /**
     * @vuese
     * Select priopity Action
     * @arg object
     */
    prioritiseTask (task, priority, e) {
      e.preventDefault()
      task.priority = priority
      let object = {
        key: 'priority',
        value: task.priority,
        id: task.id
      }
      this.updateActionElement(object, e)
    },
    /**
     * @vuese
     * Select Pdca Action
     * @arg object
     */
    pdcaTask (task, pdca, e) {
      e.preventDefault()
      task.pdca = pdca
      let object = {
        key: 'pdca',
        value: task.pdca,
        id: task.id
      }
      this.updateActionElement(object, e)
    },
    /**
     * @vuese
     * update name action
     * @arg Object
     */
    ItemUpdateName (data) {
      let object = {
        id: data.id ,
        key: 'name',
        value: $('.newTaskTitle-'+data.id).html(),
      }
      this.updateActionElement(object)
    },
    /**
     * @vuese
     * change favourite action
     * @arg event
     */
    favoriteChange (e) {
      e.preventDefault()
      let object = {
        key: "favorite",
        value: this.item.favorite,
        id:this.item.id
      }
      this.updateActionElement(object, e)
    },
    /**
     * @vuese
     * fubction to update action item object
     * @arg object
     */
    updateActionElement (object, e = null) {
      if(e != null) {
        e.preventDefault()
      }
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE, {id: this.worldId, entity: 'Actions', action: object}).then((resp) => {
        this.$store.commit('actionsboard/refreshTodo', object)
        if (this.editCallback) {
          this.editCallback(object.id, object.key, object.value)
        }
      })
    },
    updateActionDate (itemId, startDate, endDate, e = null) {
      this.$store.dispatch('actionsboard/'+ ACTION_REQUEST_ACTIONS_UPDATE_DATE, {
        id: this.worldId,
        entity: 'Actions',
        action: {
          id: itemId,
          startDate: startDate,
          endDate: endDate,
        },
      }).then((resp) => {
        this.$store.commit('actionsboard/refreshTodo', {
          id: itemId,
          key: 'startDate',
          value: startDate,
        })
        this.$store.commit('actionsboard/refreshTodo', {
          id: itemId,
          key: 'endDate',
          value: endDate,
        })
        if (this.editCallback) {
          this.editCallback(itemId, 'startDate', startDate)
          this.editCallback(itemId, 'endDate', endDate)
        }
      })
    },
    /**
     * @vuese
     * this function to send erreur limit title char
     * @arg
     */
    limitInputExistance (status, id, limit) {
      this.errorTemplate = {
        element: status,
        id: id,
        limit: limit
      }
    },
    /**
     * @vuese
     * this function to send erreur limit title char is empty
     * @arg
     */
    limitInputExistanceEmpty (status, id, empty) {
      this.errorTemplateEmpty = {
        element: status,
        id: id,
        empty: empty
      }
    },
  }
}
</script>
<style lang="scss" scoped>
  .icon-sort {
    font-size: 7px;
    transform: scale(1.8);
    padding: 0 3px;
  }
  #newTaskTitle {
    max-height: 40px;
    overflow: hidden;
  }
  .text-striked {
    text-decoration: line-through rgba(black, .5);
  }
  .input-group {
    .form-control,
    label {
      border-color: white;
    }
    span {
      input {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &:not(:last-child) {
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &.date {
        input {
          text-align: center;
        }
      }
    }
  }
  #newTask .priority-dropdown-link-wrapper  {
    border-radius: 4px 0 0 4px;
  }
  .priority-dropdown-link {
    padding: 9px 11px;
  }
  .user-dropdown-link {
    line-height: 35px;
  }
  .user-img {
    width: 20px;
    height: 20px;
  }
  body.navbar-top .form-control {
        border-width: 1px;
  }
  .input-group-text{
    border: 1px solid #ddd;
  }
  .input-task-title {
    width: 100%;
     border: none;
     position: absolute;
  }
  .flex-item {
    display: flex;
    align-items: center;
  }
  .flex {
    display: flex;
  }
  .flex-date{
    display: flex;
    width: 105px
  }
  .form-control:focus[contenteditable="true"] {
    box-shadow: none !important;
    border-color: white !important;
  }
  .link-action {
    padding: 0.525rem 0 0.525rem 0.8rem;
  }
  .not-hovered {
    .dropdown-menu {
      display: none;
    }
  }
  .assigned-by {
    i {
      margin-right: -3px;
      margin-bottom: 4px;
      text-shadow: 0px 1px 0 white, 1px -1px 0 white, 1px 1px 0 white;
    }
  }
</style>
