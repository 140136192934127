<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <input :required="required" :value="value" type="url" class="form-control w-100" @change="changeVal" :placeholder="placeholder" />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <a :href="value" class="text-slate" target="_new">{{ value }}</a> <span><i class="icon-new-tab small text-grey"></i></span>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldUrl",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
