var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"editor mt-1 w-100 p-1 pb-2"},[_c('editor-floating-menu',{attrs:{"editor":_vm.editor},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var menu = ref.menu;
return [_c('div',{staticClass:"editor__floating-menu",class:{ 'is-active': menu.isActive },style:(("top: " + (menu.top) + "px"))},[_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 2 }) },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('span',{staticClass:"text"},[_vm._v("H"),_c('sup',[_vm._v("2")])])]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.heading({ level: 3 }) },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('span',{staticClass:"text"},[_vm._v("H"),_c('sup',[_vm._v("3")])])]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('i',{staticClass:"icon-list-unordered"})]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('i',{staticClass:"icon-list-ordered"})]),_c('button',{staticClass:"menubar__button",class:{ 'is-active': isActive.blockquote() },on:{"click":commands.blockquote}},[_c('i',{staticClass:"icon-quotes-left"})])])]}}])}),_c('editor-menu-bubble',{attrs:{"editor":_vm.editor,"keep-in-bounds":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var commands = ref.commands;
var isActive = ref.isActive;
var menu = ref.menu;
return [_c('div',{staticClass:"menububble",class:{ 'is-active': menu.isActive },style:(("left: " + (menu.left) + "px; bottom: " + (menu.bottom) + "px;"))},[_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.bold() },on:{"click":commands.bold}},[_c('i',{staticClass:"icon-bold2"})]),_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.italic() },on:{"click":commands.italic}},[_c('i',{staticClass:"icon-italic2"})]),(!isActive.bullet_list() && !isActive.ordered_list())?_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.heading({ level: 2 }) },on:{"click":function($event){return commands.heading({ level: 2 })}}},[_c('span',{staticClass:"text"},[_vm._v("H"),_c('sup',[_vm._v("2")])])]):_vm._e(),(!isActive.bullet_list() && !isActive.ordered_list())?_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.heading({ level: 3 }) },on:{"click":function($event){return commands.heading({ level: 3 })}}},[_c('span',{staticClass:"text"},[_vm._v("H"),_c('sup',[_vm._v("3")])])]):_vm._e(),(!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 }))?_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.bullet_list() },on:{"click":commands.bullet_list}},[_c('i',{staticClass:"icon-list-unordered"})]):_vm._e(),(!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 }))?_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.ordered_list() },on:{"click":commands.ordered_list}},[_c('i',{staticClass:"icon-list-ordered"})]):_vm._e(),_c('button',{staticClass:"menububble__button",class:{ 'is-active': isActive.blockquote() },on:{"click":commands.blockquote}},[_c('i',{staticClass:"icon-quotes-left"})])])]}}])}),_c('editor-content',{staticClass:"editor__content",attrs:{"editor":_vm.editor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }