var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mode == 'edit')?[_c('places',{staticClass:"form-control",attrs:{"required":_vm.required,"value":_vm.addressLabel,"options":{
        type: _vm.config && _vm.config.address_type ? _vm.config.address_type : 'address',
        templates: {
          value: function(suggestion) {
            var label = suggestion.name
            if (suggestion.city && suggestion.city && suggestion.city != '') {
              label = label + ', ' + suggestion.city
            }
            else if (suggestion.administrative && suggestion.administrative && suggestion.administrative != '') {
              label = label + ', ' + suggestion.administrative
            }
            if (suggestion.country && suggestion.country != '') {
              label = label + ', ' + suggestion.country
            }
            return label
          }
        }},"placeholder":_vm.placeholder},on:{"change":function (val) { _vm.changeVal(val.hit) }}})]:_vm._e(),(_vm.mode == 'view')?[_c('a',{staticClass:"float-right",attrs:{"href":_vm.googleMapsUrl,"target":"_new"}},[_vm._v(" "+_vm._s(_vm.$t('moduleentity.fields.address.google_maps_link'))+" "),_c('i',{staticClass:"icon-new-tab small"})]),_c('div',[(_vm.value.locale_names && _vm.value.locale_names[0])?[_vm._v(" "+_vm._s(_vm.value.locale_names[0]))]:_vm._e()],2),_c('div',[(_vm.value.postcode && _vm.value.postcode[0])?[_vm._v(_vm._s(_vm.value.postcode[0])+" ")]:_vm._e(),(_vm.value.city && _vm.value.city[0])?_c('span',[_vm._v(_vm._s(_vm.value.city[0]))]):(_vm.value.county && _vm.value.county[0])?_c('span',[_vm._v(_vm._s(_vm.value.county[0]))]):_vm._e()],2),(_vm.value.country)?_c('div',[_vm._v(_vm._s(_vm.value.country))]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }