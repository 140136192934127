<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <label v-for="(option, optionKey) in config.options" class="d-block cursor-pointer">
        <i class="mr-1 icon-checkbox-checked2 text-success" v-if="clonedValue && clonedValue.includes(optionKey)"></i>
        <i class="mr-1 icon-checkbox-unchecked2 text-grey" v-else></i>
        <input :id="id + '-' + optionKey" class="d-none" :value="optionKey" type="checkbox" v-model="clonedValue" @change="!emitting ? changeVal(clonedValue) : ''">
        <template v-if="config.reference">
          {{ option }}
        </template>
        <template v-else>
          {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${option}`) }}
        </template>
      </label>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <ul>
        <template v-if="config.reference">
          <li v-for="elem in value">{{ config['options'][elem] }}</li>
        </template>
        <template v-else>
          <li v-for="elem in value">
          {{
            $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${elem}`) ?
              $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${elem}`) :
              elem
          }}
        </li>
        </template>
      </ul>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldCheckboxes",
  extends: Field,
  components: {
  },
  data () {
    return {
      emitting: false,
      clonedValue: [],
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.emitting = true
      this.$emit('input', val)
      this.emitting = false
    },
  },
  computed: {
  },
  watch: {
    value (newVal) {
      this.clonedValue = _.cloneDeep(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
