<!-- Component Pdca for action -->
<template>
  <div>
    <div class="pie">
      <div v-if="value == 'plan'" class="pie__segment" style="--offset: 0; --value: 25;"></div>
      <div v-if="value == 'plan'" class="pie__segment" style="--offset: 25; --value: 50; --bg:#F0F0F0"></div>
      <div v-if="value == 'plan'" class="pie__segment" style="--offset: 75; --value: 25; --bg:#F0F0F0"></div>

      <div v-if="value == 'do'" class="pie__segment" style="--offset: 0; --value: 50;"></div>
      <div v-if="value == 'do'" class="pie__segment" style="--offset: 50; --value: 50; --bg:#F0F0F0"></div>

      <div v-if="value == 'check'" class="pie__segment" style="--offset: 0; --value: 50;"></div>
      <div v-if="value == 'check'" class="pie__segment" style="--offset: 50; --value: 25;"></div>
      <div v-if="value == 'check'" class="pie__segment" style="--offset: 75; --value: 25; --bg:#F0F0F0"></div>

      <div v-if="value == 'act'" class="pie__segment" style="--offset: 0; --value: 50;"></div>
      <div v-if="value == 'act'" class="pie__segment" style="--offset: 50; --value: 50;"></div>
    </div>
  </div>
</template>

<script>

/**
 * @group _ Module Actionsboard
 * This component display Pdca piechart
 */
export default {
  name: 'Pdca',
  /**
  * @vuese
  * Get pie value for action
  */
  props: ['value'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="css" scoped>
.pie {
  border-radius: 100%;
  height: 20px;
  overflow: hidden;
  position: relative;
  width: 20px;
}
.pie__segment {
  --a: calc(var(--over50, 0) * -100%);
  --b: calc((1 + var(--over50, 0)) * 100%);
  --degrees: calc((var(--offset, 0) / 100) * 360);
  -webkit-clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
  clip-path: polygon(var(--a) var(--a), var(--b) var(--a), var(--b) var(--b), var(--a) var(--b));
  height: 100%;
  position: absolute;
  -webkit-transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
          transform: translate(0, -50%) rotate(90deg) rotate(calc(var(--degrees) * 1deg));
  -webkit-transform-origin: 50% 100%;
          transform-origin: 50% 100%;
  width: 100%;
  z-index: calc(1 + var(--over50));
}
.pie__segment:after,
.pie__segment:before {
  background: var(--bg, #9AC55F);
  content: '';
  height: 100%;
  position: absolute;
  width: 100%;
}
.pie__segment:before {
  --degrees: calc((var(--value, 45) / 100) * 360);
  -webkit-transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
          transform: translate(0, 100%) rotate(calc(var(--degrees) * 1deg));
  -webkit-transform-origin: 50% 0%;
          transform-origin: 50% 0%;
}
.pie__segment:after {
  opacity: var(--over50, 0);
}

</style>
