<template>

  <div>
      <el-alert>
        {{ $t(`modules.${$route.params.module}.entities.${entityType}.fields.${id}`) }}
      </el-alert>
  </div>

</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldInfo",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
