import { render, staticRenderFns } from "./ProcessPage.vue?vue&type=template&id=7efa0b54&scoped=true&"
import script from "./ProcessPage.vue?vue&type=script&lang=js&"
export * from "./ProcessPage.vue?vue&type=script&lang=js&"
import style0 from "./ProcessPage.vue?vue&type=style&index=0&id=7efa0b54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7efa0b54",
  null
  
)

export default component.exports