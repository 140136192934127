<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <label v-for="(option, optionKey) in config.options" class="d-block cursor-pointer">
        <i class="mr-1 icon-radio-checked text-success" v-if="option == value"></i>
        <i class="mr-1 icon-radio-unchecked text-grey" v-else></i>
        <input :id="id + '-' + optionKey" class="d-none" :value="optionKey" type="radio" @click="changeVal(optionKey)">
        <template v-if="config.reference">
          {{ option }}
        </template>
        <template v-else>
          {{ $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${option}`) }}
        </template>
      </label>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <template v-if="config.reference">
        {{ value }}
      </template>
      <template v-else>
        {{
          $te(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options`) ?
            $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.options.${value}`) : $t(`modules.${$route.params.module}.entities.${module.pages[$route.params.page].render.data}.fields.${id}.${value}`)
        }}
      </template>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldRadios",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
</style>
