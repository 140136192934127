<template>
  <div class="actions-field">

    <TodoCreate
      :dataId="entity.id"
      :users="users"
      ref="childCreate"
      :priorities="priorities"
      :formatSelectedDate="formatSelectedDate"
      :addCallback="addCallback"
    />

    <TodoActionsList
      :show_sort="actions.length > 1"
      :formatSelectedDate="formatSelectedDate"
      :actions="actions"
      :priorities="priorities"
      :users="users"
      :editCallback="editCallback"
    />

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">
    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">
    </template>

  </div>
</template>

<script>

import Field from './_Field'

import TodoCreate from '@/../Modules/Actionsboard/pages/component/_TodoCreate'
import TodoActionsList from '@/../Modules/Actionsboard/pages/component/_TodoActionsList'

import moment from 'moment'

export default {
  name: "ModuleFormFieldActions",
  extends: Field,
  components: {
    TodoCreate,
    TodoActionsList,
  },
  data () {
    return {
      actions: [],
      priorities: {
        high: {
          title: this.$t('todos.priorities.high'),
          icon: 'icon-arrow-up16',
          class: 'danger',
          number: 40,
        },
        medium: {
          title: this.$t('todos.priorities.medium'),
          icon: 'icon-arrow-up7',
          class: 'orange',
          number: 30,
        },
        low: {
          title: this.$t('todos.priorities.low'),
          icon: 'icon-arrow-up7',
          class: 'yellow',
          number: 20,
        },
        none: {
          title: this.$t('todos.priorities.none'),
          icon: 'icon-arrow-down7',
          class: 'grey',
          number: 10,
        }
      },
    }
  },
  mounted () {
    this.$set(this, 'actions', _.cloneDeep(this.entity[this.id]))
  },
  methods: {
    formatSelectedDate(val, placeholder = null) {
      let currentYear = new Date().getFullYear()
      // val = val.replace('/' + currentYear, '').replace('/' + currentYear, '')
      if (val == '' || val == null) {
        if (!placeholder) {
          return '<i class="icon-calendar22 text-grey"></i>'
        } else {
          return placeholder
        }
      } else {
        let vals = val.split(' - ')
        vals[0] = moment(vals[0], "DD/MM/YYYY")
        vals[1] = moment(vals[1], "DD/MM/YYYY")
        if (vals[0].toString() === vals[1].toString()) {
          val = this.$d(vals[0].toDate(), vals[0].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
        } else {
          vals[0] = this.$d(vals[0].toDate(), vals[0].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
          vals[1] = this.$d(vals[1].toDate(), vals[1].format('YYYY') != currentYear ? 'shortText' : 'shortTextWoYear')
          val = vals.join(' - ')
        }
      }
      return val.replace('-', '<span class="text-grey">→</span>')
    },
    changeVal(val) {
      this.$emit('input', val)
    },
    addCallback (todo) {
      this.actions.push(todo)
      let actionIndex = this.entity[this.id].push(todo)
    },
    editCallback (id, key, value) {
      // Update the value directly into the form
      let actionIndex = this.entity[this.id].findIndex(action => action.id == id)
      this.$set(this.entity[this.id][actionIndex], key, value)
    },
  },
  computed: {
    users () {
      let users = this.$store.getters['world/getUsers'] ? this.$store.getters['world/getUsers'] : [];
      return users
    },
  },
  watch: {
    value (newVal) {
      if (newVal) {
        this.clonedValue = newVal
      } else {
        this.clonedValue = null
      }
    },
    clonedValue (newVal) {
      this.changeVal(newVal)
    }
  },
}
</script>

<style lang="scss" scoped>
.actions-field {
  /deep/ {
    a {
      border-bottom: 0 !important;
    }
    span.user {
      &:before,
      &:after {
        display: none;
      }
      background: none;
    }
  }
}
</style>
