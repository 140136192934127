<template>

  <div>

    <div class="w-100">
      <div :class="`badge badge-${classColorName} mb-1`" v-tooltip="$t('modules.process.process.template.addons.textfield.placeholder')">{{ $t('modules.process.process.template.addons.textfield.name') }}</div>
      <input :value="value" type="text" class="form-control w-100" @change="changeVal" :placeholder="$t('modules.process.process.template.addons.textfield.placeholder')" />
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-textfield',
  props: ['value', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val.target.value)
    }
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
</style>
