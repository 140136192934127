import { render, staticRenderFns } from "./_TodoListing.vue?vue&type=template&id=5b0508fb&scoped=true&"
import script from "./_TodoListing.vue?vue&type=script&lang=js&"
export * from "./_TodoListing.vue?vue&type=script&lang=js&"
import style0 from "./_TodoListing.vue?vue&type=style&index=0&id=5b0508fb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0508fb",
  null
  
)

export default component.exports