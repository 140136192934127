<!-- Component Commentaire -->
<template>
  <div>
    <el-alert type="warning" v-if="noComment">
      <span> {{ $t(module+'.todos.comment_vide') }} </span>
    </el-alert>
    <textarea
      class="comment form-control w-100"
      :placeholder="$t('todos.comment_add')"
      v-model="text"
      @input="onChange"
    >
    </textarea>
    <div class="text-right mt-2">
      <span @click="addComment(text)" class="btn btn-success text-white btn-sm w-100">
        {{ $t(module+'.todos.comment_send') }}
      </span>
    </div>
    <div v-if="dataComments.length > 0" class="small text-grey text-center mt-3 text-center" @click="commentsOpened = !commentsOpened">
      <div class="block cursor-pointer" v-if="commentsOpened">{{ $t('todos.comment_view') }} ({{dataComments.length }})</div>
      <div class="block cursor-pointer" v-if="!commentsOpened">{{ $t('todos.comment_close') }} ({{ dataComments.length }})</div>
    </div>
    {{ }}
    <div v-if="dataComments.length == 0 && !commentsOpened" class="small text-center mt-3 text-grey">
      {{ $t('todos.comment_none') }}
    </div>
    <div class="mt-2" v-if="!commentsOpened">
      <ul class="media-list">
          <li class="media" v-for="item in dataComments">
            <img :src="userComment(item.author).avatar.thumb" class="avatar mt-1 rounded-circle mr-2">
            <div class="media-body">
              <div class="text-primary font-weight-semibold">
                {{ userComment(item.author).full_name}}
              </div>
             <span class="text-muted d-block">
               {{ item.content }}
             </span>
           </div>
          </li>
      </ul>
    </div>
  </div>
</template>

<script>

import store from '@/store'
import {
ACTION_STORE_COMMENT
} from '../../../mutations-types'
/**
 * @group _ Module Actionsboard
 * This component display comments list
 */
export default {
  /**
   * @vuese
   * Get data commit in action
   * @type Array
   */
   /**
    * @vuese
    * Get id action
    * @type integer
    */
  props: ['comments', 'id'],
  name: 'Comments',
  data () {
    return {
      commentsOpened: false,
      dataComments: [],
      text: '',
      worldId: store.getters['auth/getWorld'].id,
      user: this.$store.getters['auth/getProfile'],
      module: 'modules.'+this.$route.meta.moduleName,
      noComment: false,
      users: store.state.actionsboard.listUsers
    }
  },
  mounted () {
    if(this.comments != null)
      this.dataComments = this.comments.reverse()
  },
  methods: {
    /**
     * @vuese
     * Add comment for action
     * @arg object
     */
    addComment (task) {
      if ($.trim(task) !== '') {
        let comment = {
          id: this.id,
          author: this.user.id,
          content: task
        }
        this.$store.dispatch('actionsboard/'+ ACTION_STORE_COMMENT, {id: this.worldId, entity: 'Actions', action: comment}).then((resp) => {
          this.$store.commit('actionsboard/refreshTodo', {comment: comment, key: 'comment', id: this.id  })
          let action = this.$store.state.actionsboard.todos.filter(item => item.id === this.id )
          this.dataComments = action[0].comment
        })
        task = ''
        this.text = ''
      } else {
        this.noComment = true
      }
    },
    /**
     * @vuese
     * Get user how create commentaire
     * @arg integer
     */
    userComment (author) {
      if(author == this.user.id) {
        return this.user
      }
      let userComment = {}
      this.users.map(item => {
        if(author == item.id) {
          return userComment = item
        }
      })
      return userComment
    },
    /**
     * @vuese
     * Check length comment
     */
    onChange () {
      if(this.text.length > 0) {
          this.noComment = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  span {
    font-size: 10px;
  }
}
body.navbar-top .alert.alert-styled-left {
    border-left-width: 1.875rem;
}
.alert[class*=alert-styled-]:after {
    width: 1.875rem;
    left: -1.875rem;
}
.avatar {
  width: 34px;
  height: 34px;
}
</style>
