<template>
  <div>
    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">
      <div v-if="endpoint">
        <div ref="dropZone" @dragover="resumableDrag=true" @drop="resumableDrag=false" @dragleave="resumableDrag=false">
          <!-- Affichage des fichiers -->

          <div class="resumable-list">
            <div class="small bg-light p-2" v-if="fileList && Object.keys(fileList).length > 0">
              <div v-for="(file, fileId) in fileList" :key="file.uniqueIdentifier" class="pb-1">
                  <span class="">
                    <i class="float-right icon-cross3 text-danger cursor-pointer" style="margin-top: 2px;" @click="removeFile(fileId)" />
                    <span v-if="!file.error" class="float-right badge bg-greylight text-dark font-weight-normal ml-2">{{ file.size | filesize }}</span>
                    <span class="resumable-file-name">
                      <i :class="fileIcon(file.type) + ' mr-1 text-slate'" />
                      <span v-if="file.loading || file.error || !file.completed" class="text-grey d-inline-block w-50 px-0">
                        {{ file.fileName }}
                      </span>
                      <span v-else>
<!--                         <a :href="file.url" target="_new">
                          <i class="icon-new-tab small text-grey mr-1" />
                        </a> -->
                        <input
                          type="text"
                          class="input-file-name border-0 rounded w-50 px-0"
                          v-model="file.fileReadableName"
                          @blur="file.fileReadableName == '' ? file.fileReadableName = file.fileName : null"
                        >
                      </span>
                    </span>
                  </span>
                  <i v-if="file.loading && !file.error" class="fa fa-spin fa-spinner text-secondary mr-1" />
                  <div class="d-inline-block" v-if="!file.completed && !file.error">
                    <div class="progress-container rounded d-inline-block">
                      <div class="progress-bar rounded" :style="'width: ' + file.progress * 100 + '%'"></div>
                    </div>
                    <span class="small resumable-file-progress">
                        {{ Math.floor(file.progress * 100) + '%' }}
                    </span>
                  </div>
                  <div class="d-inline-block" v-if="file.error">
                    <i class="icon-warning22 small text-danger" /> <span class="small text-danger">Error</span>
                  </div>
              </div>
            </div>
          </div>

          <div class="drop-zone p-0 form-control cursor-pointer text-center rounded position-relative" ref="dropZoneDashed" :class="{'resumable-dragover': resumableDrag}">
            <div class="bg position-absolute" v-if="totalProgress > 0 && totalProgress < 100" :style="'width: ' + totalProgress * 100 + '%'"></div>
            <div class="p-2 position-relative text small font-weight-semibold text-grey">
              {{ $t('common.dropfile_btn') }}
            </div>
          </div>
          <!-- <button class="btn btn-success mt-2" ref="uploadFiles" @click="upload">Start Upload</button> -->

        </div>

      </div>
      <el-alert v-else type="warning">
        Dropfile endpoint issue
      </el-alert>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

        <div class="" v-for="file in value" :key="file.uniqueIdentifier">
            <a :href="file.url" target="_new" class="p-1 d-inline-block file-view-link text-dark rounded">
              <span class="resumable-file-name">
                <i :class="fileIcon(file.type) + ' mr-1 text-slate'" />
                {{ (file.fileReadableName && file.fileReadableName != '') ? file.fileReadableName : file.fileName }}
                <i class="icon-new-tab small text-grey" />
              </span>
              <span class="badge bg-greylight text-dark font-weight-normal ml-2 small">{{ file.size | filesize }}</span>
            </a>
        </div>

    </template>

  </div>
</template>

<script>

import axios from 'axios'
import _ from 'lodash'
import Resumable from '@/assets/js/resumable'

import Field from './_Field'

export default {
  name: "ModuleFormFieldDropfile",
  extends: Field,
  components: {
  },
  data () {
    return {
      endpoint: process.env.VUE_APP_DROPFILE_ENDPOINT,
      resumableDrag: false,
      resumable_uploader: null,
      fileList: this.value ? this.value : [],
      uploadToken: 'my_token',
      chunkSize: 1 * 1024 * 1024,
    }
  },
  mounted () {
  },
  methods: {
    upload () {
      this.resumable_uploader.upload();
    },
    removeFile (fileId) {
      this.$delete(this.fileList, fileId)
    },
    destroyDropfile () {
      this.resumable_uploader = null
    },
    initDropfile () {
      this.resumable_uploader = new Resumable({
        target: this.endpoint,
        withCredentials: true,
        testChunks: true,
        fileType: this.config.formats ? this.config.formats : [],
        query: {
          upload_token: this.uploadToken,
        },
        maxChunkRetries: 2,
        maxFiles: 10,
        prioritizeFirstAndLastChunk: true,
        simultaneousUploads: 4,
        chunkSize: this.chunkSize,
      })
      if(!this.resumable_uploader.support) {
        alert('Resumable not supported')
      }
      this.resumable_uploader.assignDrop(this.$refs.dropZone)
      this.resumable_uploader.assignBrowse(this.$refs.dropZoneDashed)
      this.resumable_uploader.on('fileAdded', (file) => {
        this.fileList.push({
          'url': null,
          'crc': null,
          'uniqueIdentifier': file.uniqueIdentifier,
          'fileName': file.fileName,
          'fileReadableName': file.fileName,
          'progress': 0,
          'size': file.size,
          'type': file.file.type,
          'cancel': () => file.cancel(),
          'completed': false,
          'loading': false,
          'error': false,
        })
        this.upload()
      })
      this.resumable_uploader.on('fileProgress', (file, ratio) => {
        let fileIndex = _.findIndex(this.fileList, {'uniqueIdentifier' : file.uniqueIdentifier})
        this.$set(this.fileList[fileIndex], 'loading', true)
        this.$set(this.fileList[fileIndex], 'progress', file.progress())
      })
      this.resumable_uploader.on('fileSuccess', (file, crc) => {

        // Final call to get the file path
        this.getFilePath(file).then(filePathData => {
          let fileIndex = _.findIndex(this.fileList, {'uniqueIdentifier' : file.uniqueIdentifier})
          this.$set(this.fileList[fileIndex], 'completed', true)
          this.$set(this.fileList[fileIndex], 'loading', false)
          this.$set(this.fileList[fileIndex], 'crc', crc)
          this.$set(this.fileList[fileIndex], 'url', this.endpoint + '/' + filePathData.data)
        })


      })
      this.resumable_uploader.on('fileError', (file, message) => {
        let fileIndex = _.findIndex(this.fileList, {'uniqueIdentifier' : file.uniqueIdentifier})
        this.$set(this.fileList[fileIndex], 'error', true)
        this.$set(this.fileList[fileIndex], 'completed', false)
        this.$set(this.fileList[fileIndex], 'loading', false)
      })
    },
    getFilePath (file) {
      let args = {
        'resumableChunkNumber': '-',
        'resumableChunkSize': this.chunkSize,
        'resumableCurrentChunkSize': this.chunkSize,
        'resumableTotalSize': file.size,
        'resumableIdentifier': file.uniqueIdentifier,
        'resumableTotalChunks': file.chunks.length,
        'resumableFilename': file.fileName,
        'resumableRelativePath': file.relativePath,
        'resumableType': file.file.type,
        'upload_token': this.uploadToken,
      }
      let urlToCall = this.endpoint + '?' + new URLSearchParams(args).toString()

      return axios.get(this.endpoint, {
        params: args,
      })
    },
    changeVal(val) {
      this.$emit('input', val)
    },
    fileIcon (mimetype) {
      let icon = 'icon-file-download'
      if (mimetype) {
        switch (mimetype) {
          case 'audio/midi':
          case 'audio/ogg':
          case 'audio/x-wav':
          case 'audio/aac':
          case 'audio/mpeg':
          case 'audio/x-ms-wma':
            icon = 'icon-file-music'
           break;
          case 'application/pdf':
            icon = 'icon-file-pdf'
           break;
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            icon = 'icon-file-word'
           break;
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            icon = 'icon-file-excel'
           break;
          case 'application/zip':
            icon = 'icon-file-zip'
           break;
          case 'text/css':
          case 'text/html':
          case 'text/javascript':
            icon = 'icon-file-css'
           break;
          case 'image/png':
          case 'image/jpeg':
          case 'image/gif':
          case 'image/svg+xml':
            icon = 'icon-file-picture'
            break;
          case 'video/mpeg':
          case 'video/mp4':
          case 'video/quicktime':
          case 'video/x-ms-wmv':
          case 'video/x-msvideo':
            icon = 'icon-file-video'
            break;
        }
      }
      return icon
    }
  },
  computed: {
    totalProgress () {
      let total = 0
      let items = 0
      Object.keys(this.fileList).forEach( fileKey => {
        let file = this.fileList[fileKey]
        if (!file.completed && !file.error && file.loading) {
          items++
          total = total + file.progress
        }
      })
      return total/items
    },
    validFiles () {
      return _.filter(this.fileList, file => {
        return file.completed && !file.error && !file.loading && file.size > 0 && file.url
      })
    }
  },
  watch: {
    fileList: {
      deep: true,
      handler(newVal, oldVal) {
        this.changeVal(this.validFiles)
      },
    },
    mode: {
       immediate: true,
       handler(newVal, oldVal) {
        if (newVal == 'edit') {
          this.$nextTick(() => {
            this.initDropfile()
          })
        }
        if (newVal == 'view') {
          this.$nextTick(() => {
            this.destroyDropfile()
          })
        }
      },
    },
  }
}
</script>

<style lang="scss" scoped>
.drop-zone {
  border-style: dashed;
  &:hover {
    border-color: #bbb;
  }
  .bg {
    transition: all .15s ease;
    background: #e6f8db;
    height: 100%;
  }
  .text {
    z-index: 2;
  }
}
.progress-bar {
  height: 6px;
  background: green;
}
.resumable-dragover {
  transition: all .3s ease;
  border-color: #78c07e;
  border-width: 4px;
}
.progress-container {
  background: #ccc;
  width: 150px;
}
.input-file-name {
  background: none;
  &:hover {
    background: rgba(black, .02);
  }
  &:focus {
    background: rgba(black, .03);
  }
}
.file-view-link {
  border: 0;
  &:hover {
    background: rgba(black, .02);
  }
}
</style>
