<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <el-select
        :nullOption="true"
        :allowClear="true"
        :options="usersOptions"
        v-model="clonedValue"
      />

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <el-user-teaser :user="getUser(value)"/>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldUser",
  extends: Field,
  components: {
  },
  data () {
    return {
      clonedValue: null,
    }
  },
  mounted () {
    this.clonedValue = this.value
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
    getUser (getId) {
      return this.users.find(user => {
        return getId == user.id
      })
    },
  },
  computed: {
    users () {
      let users = this.$store.getters['world/getUsers'] ? this.$store.getters['world/getUsers'] : [];
      return users
    },
    usersOptions () {
      let usersOptions = []
      _.map(this.users, (elem) => {
        let push = true
        if (this.config.limit && this.config.limit.module && this.config.limit.permission) {
          if (!this.userHasPermission(this.config.limit.module, this.config.limit.permission, elem.id)) {
            push = false
          }
        }
        if (push) {
          usersOptions.push({
            avatar: elem.avatar.thumb,
            value: elem.id,
            title: elem.full_name ? elem.full_name : elem.email,
          })
        }
      })
      return usersOptions
    },
  },
  watch: {
    value (newVal) {
      if (newVal) {
        this.clonedValue = newVal
      } else {
        this.clonedValue = null
      }
    },
    clonedValue (newVal) {
      this.changeVal(newVal)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
