<!-- Component PickDate for action -->
<template>
  <div class="input-group mb-2 cursor-pointer">
    <div class="input-group-prepend">
      <span class="input-group-text" data-toggle="tooltip" :title="data.placeholder"><i :class="data.icon"></i></span>
    </div>
    <input
      type="text"
      v-model="timestamp"
      field-data="timestamp"
      :field-render="timestamp"
      :class="'cursor-pointer form-control form-control-lg bg-white px-2 ' + 'datepicker'+data.type"
      :placeholder="data.placeholder"
    />
  </div>
</template>

<script>

import store from '@/store'
import { ACTION_REQUEST_ACTIONS_UPDATE } from '../../../mutations-types'
import moment from 'moment'
/**
 * @group _ Module Actionsboard
 * This component display date picker
 */
export default {
  name: 'Pickadate',
  /**
  * @vuese
  * Get data date for action
  */
  props: ['data'],
  data () {
    return {
      timestamp: null,
      worldId: store.getters['auth/getWorld'].id,
      show: false
    }
  },
  mounted () {
    let app = this
    this.initDatepicker()
    this.timestamp = this.data.timestamp
  },
  methods: {
    /**
     * @vuese
     * Init Date picker
     * @arg
     */
    initDatepicker () {
      let app = this
      let local = localStorage.getItem('user-language')
      let localObject = {}
      /**
       * @vuese
       *  get langague for date picker
       *
       */
      if(local == 'fr-fr' || local == 'fr' || local == null) {
        localObject = {
          monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          monthsShort: [ 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec' ],
          weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
          today: 'Aujourd\'hui',
          clear: 'Clair',
          close: 'Fermer'
        }
      }
      $('.datepicker'+this.data.type).pickadate({
        ...localObject,
        format: 'd mmm, yyyy',
        min: moment().format(),
        onSet: function (context) {
          app.timestamp  = $(this)[0].$node.val()
        },
        onClose: function() {
          /**
           * @vuese
           * Before Close Date Picker change date action and update
           *
           */
            if($(this)[0].$node.val() != '') {
              let object = {id: app.data.id, key: app.data.type, value: $(this)[0].$node.val()}
              app.$emit('updateDate', object)
              app.$store.commit('actionsboard/refreshTodo', object)
              let el = '.taskSelectedIs_'+app.data.id
              let date = $(this)[0].$node.val()
              if ($(el).find('[type="date"]').length > 0 && moment($(this)[0].$node.val()).diff($(el).find('[type="date"]').attr('index')) !== 0) {
                $(el).find('[type="date"]').remove()
                $(el).html(function (i, html) {
                  return html.replace(/&nbsp;/g, '');
                });
                app.$emit('updateDate', {key: 'name', id: app.data.id, value: $(el).html()})
              }
            }
        },
        onStart: function() {
          if(app.data.timestamp != null) {
            this.set( 'select', Date.parse(app.data.timestamp) );
          }
        },
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
