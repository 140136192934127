<!-- Component Recurrent Date -->
<template>
  <div>
    <div class="modal fade" :id="id" role="dialog" :aria-labelledby="id" aria-hidden="true">
      <div :class="'modal-dialog modal-dialog-centered modal-dialog-zoom ' + modalSize" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" :aria-label="$t('common.close')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <el-alert type="warning" v-if="statusError">
              <span> {{ $t(module+'.todos.reccurence_vide') }} </span>
            </el-alert>
          </div>
          <div class="modal-body">
            <div class="recurrent-options">
              <div class="recurrent-item" v-for="item in data">
                <h3> {{$t(module+'.type.' + item.name)}} </h3>
                <div class="container-options">
                  <div clss="choix-list">
                    <label class="container" v-for="elem in item.choix">{{ $t(module+'.choix.' + Object.keys(elem)) }}
                      <input
                        type="radio"
                        v-model="choix[item.name]"
                        :value="elem[Object.keys(elem)]"
                        class="choix"
                        @click="unselected(item.name, false)"
                      >
                      <span :class="'checkmark this-'+item.name"></span>
                    </label>
                  </div>
                  <div class="form-select">
                    <label class="container">
                      <input type="radio" v-model="choix[item.name]" :value="'checkOption_'+item.name" class="multiChoix" @click="unselected(item.name, true)" checked="false">
                      <span :class="'checkmark this-'+item.name"></span>
                    </label>
                    <select multiple :id="'option_'+item.name" :name="'checkOption_'+item.name" v-model="checkOption[item.name]" :class="'option_'+item.name" :disabled="checkOption[item.name] == []">
                      <template v-if="item.multipleChoix.length == 1" >
                        <template v-for= "(option, key) in item.multipleChoix[0]">
                          <option :value="key + 1 ">{{ option }}</option>
                        </template>

                      </template>
                      <template v-if="item.multipleChoix.length > 1" >
                        <option v-for= "(option, key) in item.multipleChoix" :value="key + 1">{{ item.name == 'weekday'? $t(module+".days."+option): option }}</option>
                      </template>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center pt-3">
                <button type="submit" class="btn btn-lg btn-primary text-white" @click="setRecurrentDate($event)">{{ $t(module+'.common.valide') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const $ = require('jquery')
window.jQuery = $;
window.$ = $;
import config from '../data/config'
import store from '@/store'
import {
ACTION_REQUEST_ACTIONS_UPDATE
} from '../../../mutations-types'
/**
 * @group _ Module Actionsboard
 * This component display recurrence modal
 */
export default {
  name: "Recurrent",
  /**
  * @vuese
  * id action active, and size for modal, item is object action
  */
  props: ['id', 'size', 'item'],
  data () {
    return {
      module: 'modules.'+this.$route.meta.moduleName,
      worldId: store.getters['auth/getWorld'].id,
      data: [],
      choix:{
        days: "",
        weekday: "",
        months: ""
      },
      multiChoix: false,
      checkOption: {
        days: [],
        weekday: [],
        months: []
      },
      statusError: false
    }
  },

  mounted () {
    /**
     * @vuese
     * COnfig is algorithm of Recurrent (week days months)
     */
    this.data = config.data
    if(this.item.recurrence != null && this.item.recurrence != '') {
      let recurrence = this.item.recurrence[0].split(' ')
      let type = ['days', 'months', 'weekday']
      let format = this.item.recurrence[1].split('/')
      recurrence.forEach((item, index) => {
        if(index > 1) {
          if(format[index-2] == 'choix') {
            this.choix[type[index-2]] = item
          } else {
            this.choix[type[index-2]] = format[index-2]+type[index-2]
            this.checkOption[type[index-2]] = item.split(',')
          }
        }
      })
    }
  },
  computed: {
    /**
     * @vuese
     *  Size Modal
     */
    modalSize () {
      if (!this.size) {
        return ''
      } else {
        return 'modal-' + this.size
      }
    }
  },
  methods: {
    /**
     * @vuese
     * Change select event
     * @arg String,  Boolean
     */
    unselected (unselected, status) {
      $('.this-'+unselected).removeClass('error-'+unselected)
      $('#option_'+unselected).prop('disabled',!status);
    },
    /**
     * @vuese
     * Set Recurrent Date for action
     * @arg Event
     */
    setRecurrentDate (e) {
      e.preventDefault()
      this.statusError = false
      this.data.map(item => {
        $('.this-'+item.name).removeClass('error-'+item.name)
        if(this.choix[item.name] == '' && this.checkOption[item.name].length == 0) {
          this.statusError = true
          $('.this-'+item.name).addClass('error-'+item.name)
        }
      })
      if(this.statusError) {
        return false
      }

      let data = '0 0 ';
      let format = '';
      this.data.map( item => {
        if(this.choix[item.name] != ('checkOption_'+item.name)) {
          format +='choix/'
          data += this.choix[item.name]+ " "
        } else {
          let result = ''
          this.checkOption[item.name].forEach((value, index) => {
            if(index+1 < this.checkOption[item.name].length) {
              result += value+','
            } else {
              result += value
            }
          })
          data += result+ " "
          format +='checkOption_/'
        }
      })
      if(data != '') {
        let object = {key: 'recurrence',value: data, format: format, id: this.item.id}
        // Upadate Recurrent action  Emit Event passe data object (key, val, id)
        this.$emit('updateRecurrence', object)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (min-width: 992px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
.recurrent-options {
  display: flex;
  flex-wrap: wrap;
  .recurrent-item {
      width: 33.33%;
      .container-options {
        display: flex;
        flex-direction: row;
        .choix-list {
          width: 60%;
        }
        .form-select {
          width: 30%;
          display: flex;
          .container {
            .checkmark {
              left: 18px;
            }
          }
          select {
            option {
              padding: 0.5rem 13rem 0.5rem 0rem
            }
          }
        }
      }
    }
  h3 {
    text-align: center;
    padding-bottom: 20px;
    font-weight: 500;
  }
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: orange;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.error-days, .error-months, .error-weekday  {
  border: 2px solid #ff000075;
}
</style>
