<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="py-1">
        <span v-for="star in 5" class="pr-1 cursor-pointer" @click.prevent="changeVal(star)">
          <i :class="(value >= star) ? 'icon-star-full2 text-yellow' : 'text-slate icon-star-full2'"></i>
        </span>
      </div>

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <div class="py-1">
        <span v-for="star in 5" class="pr-1">
          <i :class="(value >= star) ? 'icon-star-full2 text-yellow' : 'text-slate icon-star-full2'"></i>
        </span>
      </div>

    </template>

  </div>
</template>

<script>

import Field from './_Field'

export default {
  name: "ModuleFormFieldRate",
  extends: Field,
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.icon-star-full2 {
  font-size: 1.6em;
}
.text-slate.icon-star-full2 {
  opacity: .3;
}
</style>
