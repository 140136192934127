<template>

    <auth-content>
      <el-header :title="$t(`modules.process.${currentType}.results.title`)" slot="header" :favorites="false">
        <div slot="left">
          <el-header-back :back="back" />
        </div>
        <div slot="actions" v-if="hasAccessToContent === true">
          <el-header-action
            :disabled="loading"
            size="xs"
            classes="btn-secondary"
            :icon="$router.resolve({name: currentType + '.template.page'}).route.meta.icon"
            :label="$t(`modules.process.${currentType}.templates.actions.edit`)"
            @click.native="$router.push({name: currentType + '.template.page', params: { world: $route.params.world, template: $route.params.template } })"
          />
        </div>
      </el-header>

      <div v-if="hasAccessToContent === false">
        <module-entity-need-access
          :entityId="parseInt($route.params.template)"
          entityModule="process"
          entityType="ProcessTemplate"
        />
      </div>

      <div v-if="hasAccessToContent === true">
        <div class="mb-3">
          <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('csv')"><i class="icon-file-text2"/> {{ $t(`modules.process.process.results.export.csv`) }}</span>
          <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('xls')"><i class="icon-file-excel"/> {{ $t(`modules.process.process.results.export.xls`) }}</span>
          <!-- <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('png')"><i class="icon-file-picture"/> {{ $t(`modules.process.process.results.export.png`) }}</span> -->
          <a class="btn btn-outline-secondary mr-2" :href="publicApiUrl" target="_new"><i class="icon-file-css"/> {{ $t(`modules.process.process.results.export.json`) }}</a>
          <!-- <a class="btn btn-outline-secondary mr-2" :href="resultsFormattedJson" download="Data.json"><i class="icon-file-css"/> {{ $t(`modules.process.process.results.export.json`) }}</a> -->
          <!-- <span class="btn btn-outline-secondary mr-2" @click="exportSpreadsheet('pdf')"><i class="icon-file-pdf"/> {{ $t(`modules.process.process.results.export.pdf`) }}</span> -->
        </div>

        <div ref="spreadsheet" class="card"></div>

        <div class="card" v-if="currentType == 'form' && isCurrentWorldOwner">
          <div class="card-header">
            <h5 class="m-0 font-weight-bold">{{ $t(`modules.process.${currentType}.api.title`) }}</h5 class="m-0">
          </div>
          <div class="card-body">
            {{ $t(`modules.process.${currentType}.api.description`) }}

            <input type="text" readonly :value="publicApiUrl" class="mt-2 form-control form-control-sm" ref="apiinput">
            <div class="mt-1 row align-items-end">
              <div class="col">
                <div class="btn btn-secondary btn-sm" @click="copyPublicApiLink()">{{ $t(`modules.process.${currentType}.api.copy_link`) }}</div>
              </div>
              <div class="col text-right">
                <div class="small cursor-pointer text-danger" @click="seeReinitLinkContent = !seeReinitLinkContent">
                  {{ $t(`modules.process.${currentType}.api.reinit_link`) }}
                  <i class="icon-arrow-down5" v-if="!seeReinitLinkContent"/>
                  <i class="icon-arrow-up5" v-else/>
                </div>
              </div>
            </div>
            <div v-if="seeReinitLinkContent" class="small px-1">
              <hr>
              {{ $t(`modules.process.${currentType}.api.reinit_link_description`) }}
              <span class="btn btn-danger btn-sm" @click="refreshAccesskey()" v-if="!loadingRefreshAccesskey">{{ $t(`modules.process.${currentType}.api.reinit_link`) }}</span>
              <loader v-else/>
            </div>
          </div>
        </div>

      </div>


    </auth-content>

</template>

<script>
const NEEDACCESS = 412

import store from '@/store'
import _ from 'lodash'

if (typeof process.env.CORDOVA_PLATFORM === 'undefined') {
  // const webix = require('@/assets/js/webix/webix/webix') // not necessary since its included in index.html
  const spreadsheet = require('@/assets/js/webix/spreadsheet/spreadsheet_debug')
}

import {
  PROCESS_GET_PROCESSTEMPLATE,
  PROCESS_GET_FORMTEMPLATE,
  PROCESS_GET_PROCESSTEMPLATERESULTS,
  PROCESS_GET_FORMTEMPLATERESULTS,
} from '../mutations-types'

import {
  MODULE_REFRESH_ACCESSKEY,
} from '@/store/mutations-types'

export default {
  name: 'ProcessTemplate',
  data () {
    return {
      hasAccessToContent: null,
      loading: true,
      loadingSpreadsheet: false,
      loadingRefreshAccesskey: false,
      spreadsheet: null,
      processTemplate: false,
      processTemplateResults: false,
      seeReinitLinkContent: false,
    }
  },
  mounted () {
  },
  methods: {
    back () {
      this.$router.push({ name:this.currentType + '.template.index', params: { world: this.$route.params.world } })
    },
    copyPublicApiLink () {
      this.$refs.apiinput.select()
      document.execCommand("copy")
    },
    refreshAccesskey () {
      this.loadingRefreshAccesskey = true
      this.$store.dispatch('module/' + MODULE_REFRESH_ACCESSKEY, {
        worldId: this.$route.params.world,
        entityId: this.$route.params.template,
      }).then(accessKey => {
        this.processTemplate.accesskey = accessKey
        this.loadingRefreshAccesskey = false
        this.notifSuccess(this.$t(`modules.process.${this.currentType}.api.reinit_link_success`) )
      })
      .catch(error => {
        this.loadingRefreshAccesskey = false
        this.notifError(error)
      })
    },
    exportSpreadsheet (format) {
      if (this.isApp() || !this.spreadsheet) {
        return
      }
      if (format == 'xls') {
        webix.toExcel(webix.$$(this.spreadsheet))
      }
      else if (format == 'csv') {
        webix.toCSV(webix.$$(this.spreadsheet))
      }
      else if (format == 'png') {
        webix.toPNG(webix.$$(this.spreadsheet))
      }
      else if (format == 'pdf') {
        webix.toPDF(webix.$$(this.spreadsheet), {
          orientation: "landscape"
        })
      }
    },
    initSpreadsheet () {
      if (this.isApp()) {
        return
      }

      this.$nextTick(() => {

        let sheetHeight = (this.processTemplateResults.length*40) + 30
        if (sheetHeight < 150) {
          sheetHeight = 150
        }

        this.spreadsheet = webix.ui({
          container: this.$refs.spreadsheet,
          $scope: this,
          bottombar: false,
          view: "spreadsheet",
          readonly: true,
          height: sheetHeight,
        })

        if (this.resultsFormatted) {
          webix.$$(this.spreadsheet).parse({
            'data': this.resultsFormatted,
            'sizes': [],
            'spans': [],
            'table': {
               "headers":1,
               "gridlines":1,
               "frozenRows":1,
               "frozenColumns":0
            },
            'locked': [],
            'ranges': [],
            'editors': [],
            'filters': [],
            'formats': [],
            'conditions': [],
            'styles': [
              ["wss1", ";;;;;;;bold;;;;;;;;"],
            ],
          })
        }

      })
    },
  },
  computed: {
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    publicApiUrl () {
      if (!this.processTemplate) {
        return ''
      }
      return `${process.env.VUE_APP_API_ROOT}${process.env.VUE_APP_API_PREFIX}/public/${this.$route.params.world}/${this.currentType}/${this.processTemplate.uuid}/json?key=${this.processTemplate.accesskey}`
    },
    currentType () {
      return this.$route.path.split('/')[3]
    },
    currentClass () {
      return this.currentType == 'form' ? 'success' : 'info'
    },
    saving () {
      return this.loading
    },
    contentParts: {
        get () {
          return this.processTemplateResults.data.content
        },
        set (value) {
          this.processTemplateResults.data.content = value
        }
    },
    resultsFormatted () {
      if (!this.processTemplateResults) {
        return []
      }
      let webix = []
      let webixHeaders = []
      let columnIds = {}
      let columnInitialId = 5
      webix.push([1, 1, 'ID', 'wss1']) // id
      webix.push([1, 2, 'Date', 'wss1']) // date
      webix.push([1, 3, 'User', 'wss1']) // user
      webix.push([1, 4, 'Mail', 'wss1']) // mail
      this.processTemplateResults.forEach((result, rowIndex) => {
        let email = null
        if (result.author) {
          email = result.author.email
        } else if (result.data.email) {
          email = result.data.email
        }

        webix.push([rowIndex + 2, 1, result.id, null]) // id
        webix.push([rowIndex + 2, 2, result.created_at, null]) // date
        webix.push([rowIndex + 2, 3, result.author ? result.author.full_name : '-', null]) // user
        webix.push([rowIndex + 2, 4, email]) // mail
        result.data.processTemplateContent.forEach((element, index) => {

          if (
            element.type == 'textfield' ||
            element.type == 'textarea' ||
            element.type == 'checklist' ||
            element.type == 'selectlist' ||
            element.type == 'rating'
          ) {

            if (!columnIds[element.id]) {
              columnIds[element.id] = Object.keys(columnIds).length + columnInitialId
            }
            let columnId = columnIds[element.id]

            if (
              element.type == 'textfield' ||
              element.type == 'textarea' ||
              element.type == 'rating'
            ) {
              webixHeaders[columnId] = element.data
            } else {
              webixHeaders[columnId] = ''
            }

            let cellVal = result.data.processValues[element.id] ? result.data.processValues[element.id] : null
            if (Array.isArray(cellVal)) {
              let formattedVal = []
              cellVal.forEach( arrVal => {
                formattedVal.push(element.data[arrVal])
              })
              cellVal = formattedVal.join(', ')
            }
            if (cellVal == null) {
              cellVal = ''
            }
            webix.push([rowIndex + 2, columnId, cellVal, null])
          }

        })
      })

      webixHeaders.forEach( (heading, index) => {
        webix.push([1, index, heading, 'wss1'])
      })
      return webix
    },
    // resultsFormattedJson () {
    //   if (!this.processTemplateResults) {
    //     return []
    //   }

    //   let json = []
    //   let jsonHeaders = []
    //   let columnIds = {}
    //   let columnInitialId = 5
    //   jsonHeaders[1] = 'id'
    //   jsonHeaders[2] = 'date'
    //   jsonHeaders[3] = 'user'
    //   jsonHeaders[4] = 'mail'
    //   let rowData = {}

    //   this.processTemplateResults.forEach((result, rowIndex) => {

    //     rowData = {}

    //     rowData[jsonHeaders[1]] = result.id
    //     rowData[jsonHeaders[2]] = result.created_at
    //     rowData[jsonHeaders[3]] = result.author.full_name
    //     rowData[jsonHeaders[4]] = result.author.email

    //     result.data.processTemplateContent.forEach((element, index) => {

    //       if (element.type == 'textfield' || element.type == 'checklist' || element.type == 'selectlist') {

    //         if (!columnIds[element.id]) {
    //           columnIds[element.id] = Object.keys(columnIds).length + columnInitialId
    //         }
    //         let columnId = columnIds[element.id]

    //         if (element.type == 'textfield') {
    //           jsonHeaders[columnId] = element.data
    //         } else {
    //           jsonHeaders[columnId] = element.type + element.id
    //         }

    //         let cellVal = result.data.processValues[element.id] ? result.data.processValues[element.id] : null
    //         if (Array.isArray(cellVal)) {
    //           let formattedVal = []
    //           cellVal.forEach( arrVal => {
    //             formattedVal.push(element.data[arrVal])
    //           })
    //           cellVal = formattedVal.join(', ')
    //         }
    //         if (cellVal == null) {
    //           cellVal = ''
    //         }
    //         rowData[jsonHeaders[columnId]] = cellVal
    //       }

    //     })
    //     json.push(rowData)
    //   })

    //   return "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json))

    // }
  },
  beforeRouteEnter (to, from, next) {
    let mutation = to.path.split('/')[3] == 'process' ? PROCESS_GET_PROCESSTEMPLATERESULTS : PROCESS_GET_FORMTEMPLATERESULTS
    store.dispatch('process/' + mutation, {
      worldId: to.params.world,
      templateId: to.params.template,
    }).then(data => {
      next(vm => {
        vm.hasAccessToContent = true
        vm.loading = false
        vm.processTemplate = data.template
        vm.processTemplateResults = data.results
      })
    }).catch(error => {
      const {status} = error.response
      if (status === NEEDACCESS) {
        next(vm => {
          vm.hasAccessToContent = false
        })
      }
    })
  },
  watch: {
    hasAccessToContent (newVal, oldVal) {
      if (!this.spreadsheet && newVal == true) {
        this.initSpreadsheet()
      }
    },
  }
}
</script>

<style lang="scss" scoped>

@import '../../../src/assets/js/webix/webix/webix.css';
@import '../../../src/assets/js/webix/spreadsheet/spreadsheet.css';

.form-control-title {
  font-size: 1.6em;
}
.d-flex.content {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
</style>
