<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="btn btn-sm btn-secondary rounded" @click="openSearchModal()">
        <i class="icon-list3 mr-1" />
        {{ placeholder }}
      </div>

      <el-modal
        :id="modalId"
        :title="placeholder"
        size="md"
      >
        <input
          v-model="searchInput"
          :ref="modalId + '-input'"
          type="search"
          class="form-control"
          :placeholder="$t('common.your_search')"
          @keyup="searchInput.length >= minimumInputLength ? loading = true : null; loadDataDebounce()"
          @keydown.enter.prevent=""
        />
        <div class="mt-3 text-center" v-if="loading"><loader /></div>
        <div class="mt-3 text-center text-grey" v-else-if="!loading && searchedOnce &&  searchResults.length == 0">{{ $t('common.no_result') }}</div>
        <div class="mt-3 small" v-if="searchResults && searchResults.length > 0">
          <div
            v-for="data in searchResults"
            class="border p-1 rounded d-flex w-100 justify-content-between align-items-center cursor-pointer autocomplete-result mb-1"
            @click="chooseResult(data)"
          >

            <div class="w-100">

              <div :key="fieldKey" v-for="(fieldConfig, fieldKey, fieldIndex) in fields">
                <div
                  class="d-flex"
                  v-if="
                    fieldConfig.type != 'info' &&
                    (
                      data[fieldKey] &&
                      (data[fieldKey] != '' || (fieldConfig.display_modes && fieldConfig.display_modes.view === true))
                    )
                ">

                  <label
                    class="font-weight-bold text-dark w-50 text-right px-1 m-0"
                    >
                    {{
                      $te(`modules.${$route.params.module}.entities.${entityType}.fields.${fieldKey}.title`) ? $t(`modules.${$route.params.module}.entities.${entityType}.fields.${fieldKey}.title`) : $t(`modules.${$route.params.module}.entities.${entityType}.fields.${fieldKey}`)
                    }}
                  </label>
                  <div class="px-1">

                    <container
                      :entity="data"
                      :entityType="entityType"
                      :index="fieldIndex"
                      v-if="!fieldConfig.multiple"
                      :id="fieldKey"
                      :config="fieldConfig"
                      :module="module"
                      :fields="fields"
                      :required="fieldConfig.required ? true : false"
                      :type="fieldConfig.type"
                      :value="data[fieldKey]"
                      :is="`module-form-field-${fieldConfig.type}`"
                      mode="view"
                    >
                      Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
                    </container>
                    <div v-else> <!-- multiple -->

                      <div class="mb-2" v-for="(value, valueIndex) in data[fieldKey].length">
                        <container
                          :entity="data"
                          :entityType="entityType"
                          :index="fieldIndex"
                          :id="fieldKey"
                          :config="fieldConfig"
                          :module="module"
                          :fields="fields"
                          :required="fieldConfig.required ? true : false"
                          :type="fieldConfig.type"
                          :value="data[fieldKey][valueIndex]"
                          :is="`module-form-field-${fieldConfig.type}`"
                          mode="view"
                        >
                          Invalid form field type : <strong>{{ fieldConfig.type }}</strong>
                        </container>
                      </div>

                    </div> <!-- end multiple -->

                  </div>
                </div>
              </div>

            </div>
            <div class="p-2">
              <el-table-action
                classes="view-link"
                icon="icon-arrow-right8"
              />
            </div>

          </div>
        </div>
      </el-modal>

    </template>

  </div>
</template>

<script>

import _ from 'lodash'
import Field from './_Field'
import { MODULE_AUTOCOMPLETE } from '@/store/mutations-types'

export default {
  extends: Field,
  name: "ModuleFormFieldAutocomplete",
  components: {
    'module-form-field-address': require('@/components/module/fields/Address.vue').default,
    'module-form-field-color': require('@/components/module/fields/Color.vue').default,
    'module-form-field-checkbox': require('@/components/module/fields/Checkbox.vue').default,
    'module-form-field-checkboxes': require('@/components/module/fields/Checkboxes.vue').default,
    'module-form-field-date': require('@/components/module/fields/Date.vue').default,
    'module-form-field-email': require('@/components/module/fields/Email.vue').default,
    'module-form-field-number': require('@/components/module/fields/Number.vue').default,
    'module-form-field-phone': require('@/components/module/fields/Phone.vue').default,
    'module-form-field-radios': require('@/components/module/fields/Radios.vue').default,
    'module-form-field-rate': require('@/components/module/fields/Rate.vue').default,
    'module-form-field-select': require('@/components/module/fields/Select.vue').default,
    'module-form-field-tags': require('@/components/module/fields/Tags.vue').default,
    'module-form-field-text': require('@/components/module/fields/Text.vue').default,
    'module-form-field-textarea': require('@/components/module/fields/Textarea.vue').default,
    'module-form-field-url': require('@/components/module/fields/Url.vue').default,
    'module-form-field-user': require('@/components/module/fields/User.vue').default,
    'module-form-field-rrule': require('@/components/module/fields/Rrule.vue').default,
  },
  data () {
    return {
      searchedOnce: false,
      loading: false,
      searchInput: '',
      searchResults: [],
      minimumInputLength: 3,
    }
  },
  mounted () {
  },
  methods: {
    chooseResult (result) {
      _.forEach(result, (fieldValue, fieldKey) => {
        this.entity[fieldKey] = fieldValue
      })
      this.closeModal(this.modalId)
    },
    openSearchModal () {
      this.openModal(this.modalId)
      this.$nextTick(() => this.$refs[this.modalId + '-input'].focus() )
    },
    loadData () {
      if (this.searchInput.length < this.minimumInputLength) {
        this.loading = false
        return
      }

      this.$store.dispatch('module/' + MODULE_AUTOCOMPLETE, {
        worldId: this.$route.params.world,
        moduleId: this.$route.params.module,
        entityType: this.module.pages[this.$route.params.page].render.data,
        field: this.id,
        search: this.searchInput,
      }).then(data => {
        this.searchedOnce = true
        this.loading = false
        this.searchResults = data
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    loadDataDebounce: _.debounce(function() {
      this.loadData()
    }, 1000),
  },
  computed: {
    modalId () {
      return `autocomplete-module-${this.$route.params.module}-${this.entityType}-${this.id}`
    },
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.autocomplete-result {
  &:hover {
    background: #FAFAFA;
  }

}
</style>
