<template>

  <div>
    <div class="w-100">
      <draggable v-model="tasksOrdered" handle=".handle">
        <div class="d-flex align-items-center w-100" v-for="index in Object.keys(tasks).length + 1">
          <i :class="`handle icon-checkbox-checked2 text-${classColorName} mr-1 cursor-move`"></i>
          <input
            :key="index - 1"
            type="text"
            class="border-0 px-1 form-control form-control-sm form-control-discreet w-100"
            v-model.lazy="tasks[index - 1]"
            :placeholder="$t('modules.process.process.template.addons.checklist.placeholder')"
            @change="changeVal()"
          />
        </div>
      </draggable>
    </div>
  </div>

</template>

<script>
import draggable from 'vuedraggable'
import _ from 'lodash'

export default {
  name: 'process-template-addon-checklist',
  props: ['value', 'classColorName'],
  components: {
    draggable,
  },
  data () {
    return {
      tasks: false
    }
  },
  mounted () {
    this.tasks = _.toArray(_.cloneDeep(this.value))
  },
  methods: {
    trimTasks () {
      let tasksTrimmed = []
      let tasks = _.toArray({...this.tasks})
      tasks = tasks.reverse()
      let startOfArray = true

      tasks.forEach(task =>  {
        if (!startOfArray || (task != '' && task != null)) {
          startOfArray = false
          tasksTrimmed.push(task)
        }
      })
      this.tasks = tasksTrimmed.reverse()
    },
    changeVal () {
      this.trimTasks()
      this.$emit('input', this.tasks)
    }
  },
  computed: {
    tasksOrdered: {
        get () {
          if (!this.tasks) {
            return []
          }
          return this.tasks
        },
        set (orderedTasks) {
          this.tasks = orderedTasks
          this.changeVal()
        }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-control {
  width: 300px;
  max-width: 100%;
}
</style>
