<template>
  <div>

    <!-- //////////////////////////
    //////////// EDIT /////////////
    /////////////////////////// -->
    <template v-if="mode == 'edit'">

      <div class="form-control d-flex align-items-center justify-content-between" :for="id" v-if="value && value.name">
        <div>
          <img v-if="value && value.urls" :src="value.urls.thumb" class="edit mr-2" />
          <span v-if="value.file_name">{{ value.file_name }}</span>
          <span v-else>{{ value.name }}</span>
          <span class="ml-1 badge badge-light">{{ value.human_filesize }}</span>
        </div>
        <i class="icon-cross2 text-danger cursor-pointer" @click="file = null; changeVal(null); $emit('removeMedia', value.id)"></i>
      </div>
      <input
        v-else
        @change="onFileChange"
        :required="required ? true : false"
        type="file"
        :field="id"
        class="form-control"
        :id="id"
        :accept="config.mimes.join(',')"
        :placeholder="placeholder"
      >

    </template>

    <!-- //////////////////////////
    //////////// VIEW /////////////
    /////////////////////////// -->
    <template v-if="mode == 'view'">

      <div class="text-center">
        <img class="view my-3" :src="value['urls']['large'] ? value['urls']['large'] : value['urls']['full']"/>
      </div>

    </template>

  </div>
</template>

<script>

import _ from 'lodash'

import Field from './_Field'

export default {
  name: "ModuleFormFieldImage",
  extends: Field,
  components: {
  },
  data () {
    return {
      file: null,
    }
  },
  mounted () {
  },
  methods: {
    onFileChange(e) {
      let field = e.srcElement.attributes.field.value

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
          return;

      this.changeVal(files[0])
    },
    changeVal(val) {
      this.$emit('input', val)
    },
  },
  computed: {
  },
  watch: {
    value (newVal) {
      this.file = _.cloneDeep(newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
img.view {
  max-width: 300px;
  max-height: 200px;
}
img.edit {
  height: 21px;
  width: 21px;
  object-fit: contain;
}
</style>
