<template>

  <div v-if="wikiContent.length > 0">

    <div class="w-100">
      <div :class="`badge badge-${classColorName} mb-1`" v-tooltip="$t('modules.process.process.template.addons.wikilink.placeholder')">{{ $t('modules.process.process.template.addons.wikilink.name') }}</div>
      <el-select :options="wikiContent" v-model="wikiLinkValue"></el-select>
    </div>

  </div>

</template>

<script>
export default {
  name: 'process-template-addon-wikilink',
  props: ['value', 'wikiContent', 'classColorName'],
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {
    wikiLinkValue: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
